import { Input } from "../../fields";
import { ConnectForm } from "../Landing";

const VideoForm = ({ name }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Video</h3>
         </div>
         <div className="p-4">
            <ConnectForm>
               {({ register }) => (
                  <>
                     <Input
                        name={`${name}.url`}
                        label="URL de Youtube"
                        register={register}
                        helpText="Escribe la dirección del video de Youtube"
                     />
                     <input type="hidden" {...register(`${name}.type`)} />
                  </>
               )}
            </ConnectForm>
         </div>
      </>
   );
};

export default VideoForm;
