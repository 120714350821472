import urlHelper from 'url';
import enums from '../constants/enums';

function parseUrl() {
    return Array.from(arguments).map(s => s.replace(/^\/+|\/+$/g, '')).join('/');
}

export function formatImage(image, resizeOptions) {
    let basePath = `https://res.cloudinary.com/itermotus/${process.env.REACT_APP_bucketAlias}/`;
    let urlString = parseUrl(basePath, image.path);
    return cloudinaryEngine(urlHelper.parse(urlString), resizeOptions || { w: 100, h: 100, fit: 'crop', quality: 'low' });
}

const cloudinaryEngine = (urlObject, options) => {
    let params = [];
    if(options.quality && options.quality === 'low'){
        params.push('q_auto:low');
    }
    params.push('f_auto');
    if (options.w) { params.push(`w_${options.w}`); }
    if (options.h) { params.push(`h_${options.h}`); }
    if (options.fit && options.fit === 'crop') { params.push(`c_fill`); }
    if (options.fit && options.fit === 'pad') { params.push(`c_pad`); }
    if (options.fit && options.fit === 'fit') { params.push(`c_fit`); }
    if (options.silhouette && options.silhouette === enums.silhouette.CIRCLE){
        params.push('ar_1');
        params.push('r_max');
    }
    let pars = params.join(',');
    let pathParts = urlObject.pathname.split('/');
    let company = pathParts.splice(1, 1);
    let path = pathParts.join('/');
    return `//${urlObject.host}/${company}/${pars}${path}`;
};
