const Loading = () => {
    return (
        <>
            <div className="w-full flex justify-between items-center bg-white border-b top-0 left-0 z-20" style={{height: '67px'}}>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="flex justify-center w-full bg-gray-50">
                    <div className="w-full max-w-full xl:max-w-screen-lg 2xl:max-w-screen-xl bg-white">
                        <div className="h-20 bg-gray-50"></div>
                        <div className="bg-gray-100 cursor-pointer border border-transparent">
                            <div className="itm-banner"></div>
                        </div>
                        <div className="bg-white h-56"></div>
                    </div>
                </div>
                <div className="flex">
                    <div className="relative lg:w-80 md:w-64 w-full">
                        <div className="md:fixed lg:w-80 md:w-64 w-full h-full flex flex-col pb-6 border-l border-gray-200 overflow-y-scroll"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loading;
