import './form.css';

const Toggle = ({ name, label, register }) => {
  
   return (
      <div className="mb-4">
          <div className="form-toggle">
            <label htmlFor={name} className="inline-block">
                <input type="checkbox" id={name} {...register(name)} />
                <span className="bar-toggle"></span>
                <span>{label}</span>
            </label>  
          </div>
      </div>
   );
};

export default Toggle;