import { ConnectForm } from "../Landing";
import { Radio, Color, Input, Check, ColorRGBA } from "../../fields";
import { Controller } from "react-hook-form";
import { AlignmentSelector } from "../../common";
import enums from "../../../constants/enums";

const ButtonForm = ({name, field}) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Botón</h3>
         </div>
         <ConnectForm>
            {({ register, watch }) => (
               <>
                  <input type="hidden" {...register(`${name}.type`)} />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Link</div>
                     <Input name={`${name}.url`} label="URL" register={register} placeholder="https://" />
                     <Check name={`${name}.openNewWindow`} label="Abrir en nueva ventana" register={register} />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Estilos</div>
                     <Controller
                        name={`${name}.styleOptions.alignment`}
                        defaultValue={field.styleOptions.alignment}
                        render={({ field: { ref, ...rest } }) => (
                           <AlignmentSelector label="Alineación" {...rest} />
                        )}
                     />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Colores</div>
                     <Radio
                        label="Colores"
                        name={`${name}.styleOptions.buttonType`}
                        register={register}
                        defaultValue={field.styleOptions.buttonType}
                     >
                        <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                        <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                     </Radio>
                     <div className={`${watch(`${name}.styleOptions.buttonType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                        <Controller
                           name={`${name}.styleOptions.buttonTextColor`}
                           defaultValue={field.styleOptions.buttonTextColor}
                           render={({ field }) => (
                              <Color label="Color de texto" {...field} />
                           )}
                        />
                        <Controller
                           name={`${name}.styleOptions.buttonBackgroundColor`}
                           defaultValue={field.styleOptions.buttonBackgroundColor}
                           render={({ field }) => (
                              <ColorRGBA label="Color de fondo" {...field} />
                           )}
                        />
                        <Controller
                           name={`${name}.styleOptions.buttonBorderColor`}
                           defaultValue={field.styleOptions.buttonBorderColor}
                           render={({ field }) => (
                              <Color label="Color del borde" {...field} />
                           )}
                        />
                     </div>
                  </div>
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default ButtonForm;
