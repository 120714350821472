import { Controller } from "react-hook-form";
import { ConnectForm } from "../Landing";
import { Color, Input, Select, Radio } from "../../fields";
import enums from "../../../constants/enums";
import validationUtils from "../../../utils/validationUtils";
import { IconButton } from "../../common";

const IconListForm = ({ name, field }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Lista con íconos</h3>
         </div>
         <ConnectForm>
            {({ register, formState: { errors }, watch }) => (
               <>
                  <input type="hidden" {...register(`${name}.type`)} />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Columnas</div>
                     <Controller
                        name={`${name}.size`}
                        defaultValue={field.size}
                        rules={{
                           validate: (value) => {
                              return value !== null && value !== undefined;
                           }
                        }}
                        render={({ field: { ref, ...rest } }) => {
                           const componentError = validationUtils.getPropertyValue(errors, name);
                           const hasError = componentError?.size?.type === 'validate';
                           return (
                              <>
                              <IconButton.Group hasError={hasError} {...rest}>
                                 <IconButton label="2" value={enums.columnTypes.W50_W50} containerClassName="w-1/3" className="h-8 w-14">
                                    <div className="w-6/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-6/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                 </IconButton>
                                 <IconButton label="3" value={enums.columnTypes.W33_W33_W33} containerClassName="w-1/3" className="h-8 w-16">
                                    <div className="w-4/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-4/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-4/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                 </IconButton>
                                 <IconButton label="4" value={enums.columnTypes.W25_W25_W25_W25} containerClassName="w-1/3" className="h-8 w-16">
                                    <div className="w-3/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-3/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-3/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                    <div className="w-3/12 h-full flex flex-col items-center justify-center">
                                       <div className="w-3 h-3 bg-black mb-1" />
                                       <div className=" w-11/12 h-2 bg-black" />
                                    </div>
                                 </IconButton>
                              </IconButton.Group>
                              { hasError && (
                                    <div className="text-red-400 mb-4 text-sm">Elegir una opción</div>
                                 )}
                              </>
                           );
                        }}
                     />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Iconos</div>
                     <Controller
                        name={(`${name}.styleOptions.iconColor`)}
                        defaultValue={field.styleOptions.iconColor}
                        render={({ field }) => (
                           <Color label="Color" {...field} />
                        )}
                     />
                     <div className="flex">
                        <Select label="Tamaño" register={register} name={`${name}.styleOptions.iconSizeCode`} defaultValue={field.styleOptions.iconSizeCode}>
                           <Select.Option value={enums.sizeCode.XS}>Muy pequeño</Select.Option>
                           <Select.Option value={enums.sizeCode.SM}>Pequeño</Select.Option>
                           <Select.Option value={enums.sizeCode.MD}>Normal</Select.Option>
                           <Select.Option value={enums.sizeCode.LG}>Grande</Select.Option>
                           <Select.Option value={enums.sizeCode.XL}>Muy Grande</Select.Option>
                        </Select>
                     </div>

                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Texto</div>
                     <Radio
                        label="Estilos"
                        name={`${name}.styleOptions.textType`}
                        register={register}
                        defaultValue={field.styleOptions.textType}
                     >
                        <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                        <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                     </Radio>

                     <div className={`${watch(`${name}.styleOptions.textType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                        <Controller
                           name={(`${name}.styleOptions.textColor`)}
                           defaultValue={field.styleOptions.textColor}
                           render={({ field }) => (
                              <Color label="Color de texto" {...field} />
                           )}
                        />
                        <div className="flex">
                           <Select label="Tamaño" register={register} name={`${name}.styleOptions.fontSizeCode`} defaultValue={field.styleOptions.fontSizeCode}>
                              <Select.Option value={enums.sizeCode.XS}>Muy pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.SM}>Pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.MD}>Normal</Select.Option>
                              <Select.Option value={enums.sizeCode.LG}>Grande</Select.Option>
                              <Select.Option value={enums.sizeCode.XL}>Muy Grande</Select.Option>
                              <Select.Option value={enums.sizeCode.CUSTOM}>Otro</Select.Option>
                           </Select>
                           {watch(`${name}.styleOptions.fontSizeCode`) === enums.sizeCode.CUSTOM && (
                              <div className="ml-4 text-transparent">
                                 <Input
                                    name={`${name}.styleOptions.fontSize`}
                                    type="number"
                                    register={register}
                                    label="Tamaño"
                                    width="70px"
                                    defaultValue={field.styleOptions.fontSize}
                                 />
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default IconListForm;
