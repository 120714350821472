import classNames from "classnames";
import { useSelector } from "react-redux";
import enums from "../../../constants/enums";
import { formatImage } from "../../../utils/imageUtils";

const Header = ({ active, onSelect, image, styleOptions, type, account, phone, email, accountCode }) => {
    const { textColor, bgColor } = styleOptions;
    const className = classNames("flex justify-center w-full cursor-pointer border", {
        "border-indigo-600": active,
        "border-transparent": !active
    });
    const {items} = useSelector(state => state.accountListWrapper);
    const selectedAccount = items.find(i=> i.code === accountCode);
    const defaultPhone = selectedAccount && selectedAccount.phone ? selectedAccount.phone : "9999999999";
    const defaultEmail = selectedAccount && selectedAccount.email ? selectedAccount.email : "example@mail.com";
    const getStyle = () => {
        let style = {};
        style.backgroundColor = bgColor;
        style.color = textColor;
        return style;
    };
    return (
        <div className={className} onClick={onSelect} style={getStyle()}>
            <div className="flex justify-between items-center w-full max-w-full xl:max-w-screen-lg 2xl:max-w-screen-xl py-2">
                <div>
                    {image ? (
                        <img src={formatImage(image, { h: 49 })} alt="logo" style={{height: '49px'}} />
                    ) : (
                        <div className="flex justify-center items-center px-4 w-64 h-14 text-gray-200 bg-gray-100">
                            <svg className="h-8 w-8" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 384" fill="currentColor">
                                <polygon points="233.92,198.08 175.253,273.6 133.333,223.253 74.667,298.667 309.333,298.667" />
                                <path d="M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
                    C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M341.333,341.333H42.667V42.667h298.667V341.333z"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                <ul className="flex list-disc list-inside">
                    <li className="mx-2 list-none">{type === enums.styleType.CUSTOM ? phone || defaultPhone : (account && account.phone && account.phone !== '' ? account.phone : defaultPhone)}</li>
                    <li className="mx-2">{type === enums.styleType.CUSTOM ? email || defaultEmail : (account && account.email && account.email !== '' ? account.email : defaultEmail)}</li>
                </ul>
            </div>
        </div>
    );
};

export default Header;
