import { ConnectForm } from '../Landing';
import { Radio, Select, Color, Input } from '../../fields';
import { Controller } from 'react-hook-form';
import enums from '../../../constants/enums';

const TextForm = ({ name, field }) => {   
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Bloque de texto</h3>
         </div>
         <div className="p-4">
            <ConnectForm>
               {({ register, watch }) => (
                  <>
                     <input type="hidden" {...register(`${name}.type`)} />
                     <Radio
                        label="Estilos"
                        name={`${name}.styleOptions.textType`}
                        register={register}
                        defaultValue={field.styleOptions.textType}
                     >
                        <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                        <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                     </Radio>
                     <div className={`${watch(`${name}.styleOptions.textType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                        <Controller
                           name={(`${name}.styleOptions.textColor`)}
                           defaultValue={field.styleOptions.textColor}
                           render={({ field }) => (
                              <Color label="Color de texto" {...field} />
                           )}
                        />
                        <Controller
                           name={(`${name}.styleOptions.backgroundColor`)}
                           defaultValue={field.styleOptions.backgroundColor}
                           render={({ field }) => (
                              <Color label="Color de fondo" {...field} />
                           )}
                        />
                        <div className="flex">
                           <Select
                              label="Tamaño"
                              register={register}
                              name={`${name}.styleOptions.fontSizeCode`}
                              defaultValue={field.styleOptions.fontSizeCode}
                           >
                              <Select.Option value={enums.sizeCode.XS}>Muy pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.SM}>Pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.MD}>Normal</Select.Option>
                              <Select.Option value={enums.sizeCode.LG}>Grande</Select.Option>
                              <Select.Option value={enums.sizeCode.XL}>Muy Grande</Select.Option>
                              <Select.Option value={enums.sizeCode.CUSTOM}>Otro</Select.Option>
                           </Select>
                           {watch(`${name}.styleOptions.fontSizeCode`) === enums.sizeCode.CUSTOM && (
                              <div className="ml-4 text-transparent">
                                 <Input
                                    name={`${name}.styleOptions.fontSize`}
                                    type="number"
                                    register={register}
                                    label="Tamaño"
                                    width="70px"
                                    defaultValue={field.styleOptions.fontSize}
                                 />
                              </div>
                           )}
                        </div>
                     </div>
                  </>
               )}
            </ConnectForm>
         </div>
      </>
   );
};

export default TextForm;
