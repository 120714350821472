import classNames from 'classnames';
import './form.css';

const TextArea = ({ name, label, helpText, register, rows, required, hasError }) => {
   const textAreaClassName = classNames("form-control", {
      "form-input-error": hasError
   });
   const labelClassName = classNames("inline-block mb-2", {
      "text-red-400": hasError
   });

   return (
      <div className="mb-4">
         {label && (
            <label htmlFor={name} className={labelClassName}>
               {
                  label
               }
            </label>
         )}
         <textarea className={textAreaClassName} rows={rows} {...register(name, { required })} />
         {helpText && (
            <small className="text-gray-400 block mb-2">
               {
                  helpText
               }
            </small>
         )}
      </div>
   );
};

TextArea.defaultProps = {
   rows: 2
};

export default TextArea;
