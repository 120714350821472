const enums = {
   elementType: {
      TEXT: 'TEXT',
      IMAGE: 'IMAGE',
      GALLERY: 'GALLERY',
      TEXT_IMAGE: 'TEXT_IMAGE',
      VIDEO: 'VIDEO',
      ICON_LIST: 'ICON_LIST',
      BUTTON: 'BUTTON',
      CALL_TO_ACTION: 'CALL_TO_ACTION',
      ROW: 'ROW',
      HEADER: 'HEADER',
      NONE: 'NONE',
      HOTEL_LIST: 'HOTEL_LIST'
   },
   columnTypes: {
      W50_W50: 'W_50_50',
      W70_W30: 'W_70_30',
      W30_W70: 'W_30_70',
      W33_W33_W33: 'W_33_33_33',
      W25_W25_W25_W25: 'W25_W25_W25_W25',
      W100: 'W_100'
   },
   widgetStyle: {
      HORIZONTAL: 'HORIZONTAL',
      VERTICAL: 'VERTICAL',
      SIDEBAR: 'SIDEBAR',
      NONE: 'NONE'
   },
   alignment: {
      LEFT: 'LEFT',
      CENTER: 'CENTER',
      RIGHT: 'RIGHT'
   },
   aspectRatio: {
      WIDE: '16:9',
      SQUARE: '1:1',
      PORTRAIT: '3:4',
      NONE: 'NONE'
   },
   silhouette: {
      CIRCLE: 'CIRCLE',
      RECTANGLE: 'RECTANGLE'
   },
   backgroundType: {
      IMAGE: 'IMAGE',
      COLOR: 'COLOR'
   },
   status: {
      DRAFT: 'DRAFT',
      PUBLISHED: 'PUBLISHED',
      INACTIVE: 'INACTIVE',
      ALL: 'ALL'
   },
   sizeCode: {
      XS: "XS",
      SM: "SM",
      MD: "MD",
      LG: "LG",
      XL: "XL",
      CUSTOM: "CUSTOM"
   },
   styleType: {
      DEFAULT: "DEFAULT",
      CUSTOM: "CUSTOM"
   },
   bannerTheme: {
      LIGHT: 'LIGHT',
      DARK: 'DARK'
   },
   previewMode: {
      DESKTOP: 'DESKTOP',
      TABLET: 'TABLET',
      MOBILE: 'MOBILE',
   },
   layout: {
      FULL_WIDTH: 'FULL_WIDTH',
      TWO_COLUMNS: 'TWO_COLUMNS'
   },
   eventType:{
      WEDDING: 'WEDDING',
      GROUP: 'GROUP',
   }
};

export default enums;
