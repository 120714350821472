import { ConnectForm } from "../Landing";
import { Radio, Photo, Select, Color, Toggle, Input, Check, ColorRGBA } from "../../fields";
import enums from "../../../constants/enums";
import { Controller } from "react-hook-form";
import { AlignmentSelector, IconButton } from "../../common";

const BannerForm = ({field, layout}) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Banner</h3>
         </div>
         
         <ConnectForm>
            {({ register, watch, formState: { errors }, control }) => (
               <>
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Banner</div>
                     <Controller
                        name='banner.images'
                        render={({ field: { ref, ...rest } }) => (
                           <Photo {...rest} aspectRatio={enums.aspectRatio.WIDE} folder={watch('account.code')} />
                        )}
                     />
                     <div className="uppercase mb-4 font-semibold">Estilo</div>
                     <Controller
                        control={control}
                        name={`banner.fullWidth`}
                        rules={{
                           validate: (value) => {
                              return value !== null && value !== undefined;
                           }
                        }}
                        defaultValue={field.fullWidth}
                        render={({ field: { ref, ...rest } }) => {
                           const hasError = errors.banner?.fullWidth?.type === 'validate';
                           return (
                              <>
                                 <div className={`mb-4 ${hasError ? "text-red-400" : ""}`}>Ancho</div>
                                 <IconButton.Group hasError={hasError} {...rest}>
                                    <IconButton label="Centrado" value={false} className="h-8 w-12">
                                       <div className="bg-gray-500 h-full w-full"></div>
                                    </IconButton>
                                    <IconButton label="Orilla a orilla" value={true} className="h-8 w-full">
                                       <div className="bg-gray-500 h-full w-full"></div>
                                    </IconButton>
                                 </IconButton.Group>
                                 { hasError && (
                                    <div className="text-red-400 mb-4 text-sm">Elegir una opción</div>
                                 )}
                              </>
                           );
                        }}
                     />
                      <Controller
                        control={control}
                        name={`banner.fullScreen`}
                        rules={{
                           validate: (value) => {
                              return value !== null && value !== undefined;
                           }
                        }}
                        defaultValue={field.fullScreen}
                        render={({ field: { ref, ...rest } }) => {
                           const hasError = errors.banner?.fullScreen?.type === 'validate';
                           return (
                              <>
                                 <div className={`mb-4 ${hasError ? "text-red-400" : ""}`}>Alto</div>
                                 <IconButton.Group hasError={hasError} {...rest}>
                                    <IconButton label="Fijo" value={false} className="h-8 w-full" buttonClassName="w-20 h-16">
                                       <div className="bg-gray-500 h-full w-full rounded-t"></div>
                                    </IconButton>
                                    <IconButton label="Pantalla completa" value={true} className="h-16 w-full" buttonClassName="w-20">
                                       <div className="bg-gray-500 h-full w-full rounded"></div>
                                    </IconButton>
                                 </IconButton.Group>
                                 { hasError && (
                                    <div className="text-red-400 mb-4 text-sm">Elegir una opción</div>
                                 )}
                              </>
                           )
                        }}
                     />
                     <input type="hidden" {...register(`banner.keepAspectRatio`)} defaultValue={field.keepAspectRatio !== undefined ? field.keepAspectRatio : true } />
                     <input type="hidden" {...register(`banner.theme`)} defaultValue={field.theme !== undefined ? field.theme : enums.bannerTheme.DARK } />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Título</div>
                     <Controller
                        name={`banner.alignment`}
                        render={({ field: { ref, ...rest } }) => (
                           <AlignmentSelector label="Alineación" {...rest} />
                        )}
                     />
                     <Select label="Tamaño"
                              register={register}
                              name={`banner.textSize`} >
                              <Select.Option value={enums.sizeCode.XS}>Muy pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.SM}>Pequeño</Select.Option>
                              <Select.Option value={enums.sizeCode.MD}>Normal</Select.Option>
                              <Select.Option value={enums.sizeCode.LG}>Grande</Select.Option>
                              <Select.Option value={enums.sizeCode.XL}>Muy Grande</Select.Option>
                              
                     </Select>
                     <Controller
                           name={(`banner.textColor`)}
                           render={({ field }) => (
                              <Color label="Color de texto" {...field} />
                           )}
                        />
                     <Toggle 
                        label={"Agregar fondo"}
                        name={`banner.hasBackgroundColor`}
                        register={register}
                     />
                     <div className={`${watch(`banner.hasBackgroundColor`) ? "block" : "hidden"}`}>
                     <Controller
                           name={(`banner.backgroundColor`)}
                           render={({ field }) => (
                              <ColorRGBA label="Color de fondo" {...field} />
                           )}
                        />
                     </div>
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Botón</div>
                     <Toggle 
                        label={"Agregar botón"}
                        name={`banner.hasButton`}
                        register={register}
                     />
                     <div className={`${watch(`banner.hasButton`) ? "block" : "hidden"}`}>
                        <Input name={`banner.button.urlButton`} label="URL" register={register} placeholder="https://" />
                        <Check name={`banner.button.openNewWindowButton`} label="Abrir en nueva ventana" register={register} />
                        <Radio
                           label="Colores"
                           name={`banner.button.buttonType`}
                           register={register}
                        >
                           <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                           <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                        </Radio>
                        <div className={`${watch(`banner.button.buttonType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                           <Controller
                              name={`banner.button.textColor`}
                              render={({ field }) => (
                                 <Color label="Color de texto" {...field} />
                              )}
                           />
                           <Controller
                              name={`banner.button.backgroundColor`}
                              render={({ field }) => (
                                 <ColorRGBA label="Color de fondo" {...field} />
                              )}
                           />
                           <Controller
                              name={`banner.button.borderColor`}
                              render={({ field }) => (
                                 <Color label="Color del borde" {...field} />
                              )}
                           />
                        </div>
                     </div>
                  </div>
                  {layout === enums.layout.TWO_COLUMNS ? (
                     <input type="hidden" {...register("banner.widgetStyle")} defaultValue={enums.widgetStyle.NONE} />
                  ) : (
                     <>
                        <hr />
                        <div className="p-4">
                           <div className="uppercase mb-4 font-semibold">Widget</div>
                           <Radio label="Estilo" name={`banner.widgetStyle`} register={register}>
                              <Radio.Option value={enums.widgetStyle.HORIZONTAL}>Horizontal</Radio.Option>
                              <Radio.Option value={enums.widgetStyle.VERTICAL}>Vertical</Radio.Option>
                              <Radio.Option value={enums.widgetStyle.NONE}>Ocultar Widget</Radio.Option>
                           </Radio>
                        </div>
                     </>
                  )}
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default BannerForm;
