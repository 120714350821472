const { useContext, createContext } = require("react");
const { default: classNames } = require("classnames");

const IconButtonGroupContext = createContext({});

function useIconButtonGroupContext() {
   const context = useContext(IconButtonGroupContext);
   if (!context) {
      throw new Error(
         `IconButton compound components cannot be rendered outside the IconButtonGroup component`,
      )
   }
   return context;
};

const IconButtonGroup = ({ children, ...rest }) => {
   return (
      <IconButtonGroupContext.Provider value={rest}>
         <div className="flex justify-between flex-wrap">
            {children}
         </div>
      </IconButtonGroupContext.Provider>
   )
};

const IconButton = ({ children, label, value, className, buttonClassName, containerClassName }) => {
   const { value: contextValue, onChange, hasError } = useIconButtonGroupContext();

   const onChangeEvent = () => {
      onChange(value);
   };
   const bClassName = classNames(`border rounded-md mb-2 flex justify-center focus:outline-none ${buttonClassName}`, {
      "bg-gray-100 border-indigo-600": contextValue === value,
      "border-gray-200 hover:bg-gray-50": contextValue !== value,
      "border-red-400": hasError 
   });

   const contClassName = classNames(`text-sm ${containerClassName}`, {
      "text-gray-400": !hasError,
      "text-red-400": hasError
   });

   return (
      <div className={contClassName}>
         <div className="mx-4 mb-4 flex flex-col justify-center items-center">
            <button
               type="button"
               className={bClassName}
               onClick={onChangeEvent}
            >
               <div className={`flex ${className}`}>
                  {children}
               </div>
            </button>
            <div className={`whitespace-nowrap ${contextValue === value ? "font-bold" : ""}`}>
               {label}
            </div>
         </div>
      </div>
   )
};

IconButton.Group = IconButtonGroup;

IconButton.defaultProps = {
   containerClassName: "w-1/2",
   buttonClassName: "py-4 w-20",
   className: "border border-gray-800 rounded-sm h-8 w-12"
};

export default IconButton;
