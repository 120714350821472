import { Auth } from "aws-amplify";

export const getChannelListWrapper = async (request) => {
   const session = await Auth.currentSession();
   const accessToken = session.getAccessToken();
   const jwt = accessToken.getJwtToken();
   let response = [];
   try {
      const data = await fetch(
         `${process.env.REACT_APP_API}/channels/${request.account}/internals `,
         {
            method: "GET",
            headers: {
               Authorization: `Bearer ${jwt}`,
            },
         }
      );
      if (data.status !== 200) {
         throw new Error("error en getEventListWrapper");
      }
      response = await data.json();
   } catch (error) {
      console.log(error);
   }
   return response;
};
