import { createContext, useContext, useState } from "react";

const MultiCheckContext = createContext();

const MultiCheck = ({value, onChange, children }) => {
   const [selected, setSelected] = useState(value ? value.map(i=> ({value: i, label: ""})): []);

   
   const onSelect = (item) => {
      let newList = [...selected];
      if(newList.find((i) => i.value === item.value )){
         newList = newList.filter(i => i.value !== item.value);
      }else{
         newList.push(item)
      }
      setSelected(newList);
      onChange(newList.map(i => i.value));
   };

   return (
      <MultiCheckContext.Provider value={{ selected, onSelect }}>
         {children}
      </MultiCheckContext.Provider>
   )
};

const Check = ({value, label, className}) => {
   const { selected, onSelect } = useContext(MultiCheckContext);
   const onClick = (e) => {
      e.stopPropagation();
      onSelect({value, label});
   };
   const isChecked = (value) => {
      return selected.find((option) => option.value === value) !== undefined;
   };
   return (
      <button type="button" className={`focus:outline-none cursor-pointer block ${className} whitespace-nowrap font-light`} onClick={onClick}>
         <input
            type="checkbox"
            className="form-check-input outline-none mr-1"
            id={value}
            checked={isChecked(value)}
            onClick={onClick}
            onChange={() => {}}
         />&nbsp;{label}
      </button>
   );
};

Check.defaultProps = {
   className: "px-4 py-1"
};

MultiCheck.Check = Check;

export default MultiCheck;
