import { createTypes, asyncAction } from 'redux-action-types';

export const types = createTypes('image', asyncAction('GET_LIST'), 'CLEAR_LIST');

export const get = (data) => ({
    type: types.GET_LIST_SUCCESS,
    data,
});

export const fetching = () => ({
    type: types.GET_LIST_REQUEST
});

export const clear = () => ({
    type: types.CLEAR_LIST
});
