import { Button } from '../../common';
import classNames from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import { StyledInput } from '../../fields';
import enums from '../../../constants/enums';

const ButtonComponent = ({ active, name, styleOptions }) => {
   const { control } = useFormContext();
   const { alignment, buttonStyle, buttonBgColor, buttonTextColor, buttonBorderColor } = styleOptions;
   const className = classNames("border p-2", {
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });
   const actionClassName = classNames("w-full py-4", {
      "text-center": alignment === enums.alignment.CENTER,
      "text-right": alignment === enums.alignment.RIGHT
   });
   const getButtonStyle = () => {
      let result = null;
      if (buttonStyle === enums.styleType.CUSTOM) {
         result = {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBgColor,
               color: buttonTextColor
            }
         };
      }
      return result;
   };
   return (
      <div className={className}>
         <div className={actionClassName}>
            <Button {...getButtonStyle()}>
               <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        className="text-2xl"
                        emptyClassName="text-gray-400"
                        {...buttonStyle === enums.styleType.CUSTOM && { textStyle: { color: buttonTextColor} }}
                        {...field}
                     />
                  )}
               />
            </Button>
         </div>
      </div>
   );
};

export default ButtonComponent;
