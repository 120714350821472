import { useTable } from "react-table";

const Table = ({ data, columns, fetching, className }) => {
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
   } = useTable({ columns, data });

   return (
      <div className={"flex flex-col overscroll-x-auto my-6"}>
         <div className="border border-gray-200 relative">
            {fetching && (
               <div className="absolute inset-0 bg-gray-200 bg-opacity-50" />
            )}
            <table {...getTableProps()} className="w-full max-w-full divide-y divide-gray-200 table-fixed">
               <thead className="bg-gray-50">
                  {headerGroups.map(headerGroup => (
                     <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                           <th {...column.width && { width: column.width }} {...column.getHeaderProps()} className="px-4 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap overflow-hidden overflow-ellipsis">
                              {
                                 column.render('Header')
                              }
                           </th>
                        ))}
                     </tr>
                  ))}
               </thead>
               <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {rows.length === 0 && fetching && (
                     <tr><td colSpan={columns.length} className="text-center px-4 py-2">Cargando ...</td></tr>
                  )}
                  {rows.length === 0 && !fetching && (
                     <tr><td colSpan={columns.length} className="text-center px-4 py-2">No se encontraron elementos</td></tr>
                  )}
                  {rows.map((row, i) => {
                     prepareRow(row);
                     return (
                        <tr {...row.getRowProps()} className={`${i % 2 !== 0 ? 'bg-gray-50' : ''} hover:bg-gray-100`}>
                           {row.cells.map(cell => (
                              <td {...cell.getCellProps()} className={`border-none px-4 py-2 whitespace-nowrap ${!cell.column?.overflowVisible ? "overflow-hidden overflow-ellipsis hover:whitespace-normal" : ""}`}>
                                 {
                                    cell.render('Cell')
                                 }
                              </td>
                           ))}
                        </tr>
                     );
                  })}
               </tbody>
            </table>
         </div>
      </div>
   )
}

export default Table;
