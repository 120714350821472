import { Auth } from "aws-amplify";

export const getHotelListWrapper = async (account) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    let response = [];
    try {
        const data = await fetch(`${process.env.REACT_APP_API}/destinations?account=${account}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },

        });
        if(data.status !== 200){
            throw new Error("error en getHotelListWrapper");
        }
        response = await data.json();
    } catch( error ){
        console.log(error);
    }
    return response;
};
