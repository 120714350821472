import { Controller, useFormContext } from "react-hook-form";
import enums from "../../../constants/enums";
import { IconButton } from "../../common";

const RowForm = ({ name, field }) => {
   const { register, control } = useFormContext();
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Columnas</h3>
         </div>
         <div className="p-4">
            <input type="hidden" {...register(`${name}.type`)} />
            <Controller
               control={control}
               name={`${name}.size`}
               defaultValue={field.size}
               render={({ field: { ref, ...rest } }) => (
                  <>
                     <div className="mb-4">Variantes</div>
                     <IconButton.Group {...rest}>
                        <IconButton label="50 / 50" value={enums.columnTypes.W50_W50}>
                           <div className="w-1/2 border-r border-gray-800 h-full"></div>
                        </IconButton>
                        <IconButton label="70 / 30" value={enums.columnTypes.W70_W30}>
                           <div className="w-8/12 border-r border-gray-800 h-full"></div>
                        </IconButton>
                        <IconButton label="30 / 70" value={enums.columnTypes.W30_W70}>
                           <div className="w-4/12 border-r border-gray-800 h-full"></div>
                        </IconButton>
                        <IconButton label="33 / 33 / 33" value={enums.columnTypes.W33_W33_W33}>
                           <div className="w-4/12 border-r border-gray-800 h-full"></div>
                           <div className="w-4/12 border-r border-gray-800 h-full"></div>
                        </IconButton>
                        <IconButton label="100" value={enums.columnTypes.W100} className="flex-col h-8 w-12">
                           <div className="w-100 border border-gray-800 h-full"></div>
                           <div className="w-100 border mt-1 border-gray-800 h-full"></div>
                        </IconButton>
                     </IconButton.Group>
                  </>
               )}
            />
            <Controller
               control={control}
               name={`${name}.notContained`}
               defaultValue={field.notContained}
               render={({ field: { ref, ...rest } }) => (
                  <>
                     <div className="mb-4">Ancho</div>
                     <IconButton.Group {...rest}>
                        <IconButton label="Centrado" value={false} className="h-8 w-12">
                           <div className="bg-gray-500 h-full w-full"></div>
                        </IconButton>
                        <IconButton label="Orilla a orilla" value={true} className="h-8 w-full">
                           <div className="bg-gray-500 h-full w-full"></div>
                        </IconButton>
                     </IconButton.Group>
                  </>
               )}
            >

            </Controller>
         </div>
      </>
   );
};

export default RowForm;
