import { ConnectForm } from "../Landing";
import { Controller } from "react-hook-form";
import { AlignmentSelector, BackgroundSelector } from "../../common";
import enums from "../../../constants/enums";
import { Photo, Input, Radio, Color, Check, ColorRGBA } from "../../fields";

const CallToActionForm = ({ name, field }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Banner</h3>
         </div>
         <ConnectForm>
            {({ register, watch }) => (
               <>
                  <div className="p-4">
                     <input type="hidden" {...register(`${name}.type`)} />
                     <div className="uppercase mb-4 font-semibold">Estilos</div>
                     <Controller
                        name={`${name}.styleOptions.backgroundType`}
                        defaultValue={field.styleOptions.backgroundType}
                        render={({ field: { ref, ...rest } }) => (
                           <BackgroundSelector label="Fondo" {...rest} />
                        )}
                     />
                     <div className={`${watch(`${name}.styleOptions.backgroundType`) === enums.backgroundType.COLOR ? "block" : "hidden"}`}>
                        <Controller
                           name={`${name}.styleOptions.backgroundColor`}
                           defaultValue={field.styleOptions.backgroundColor}
                           render={({ field }) => (
                              <Color {...field} />
                           )}
                        />
                     </div>
                     <div className={`${watch(`${name}.styleOptions.backgroundType`) === enums.backgroundType.IMAGE ? "block" : "hidden"}`}>
                        <Controller
                           name={`${name}.image`}
                           render={({ field: { ref, ...rest } }) => (
                              <Photo single {...rest} aspectRatio={enums.aspectRatio.WIDE} folder={watch('account.code')} />
                           )}
                        />
                     </div>
                     <Controller
                        name={`${name}.styleOptions.textColor`}
                        defaultValue={field.styleOptions.textColor}
                        render={({ field }) => (
                           <Color label="Color de texto" {...field} />
                        )}
                     />
                     <Controller
                        name={`${name}.styleOptions.alignment`}
                        defaultValue={field.styleOptions.alignment}
                        render={({ field: { ref, ...rest } }) => (
                           <AlignmentSelector label="Alineación" {...rest} />
                        )}
                     />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Botón</div>
                     <Input name={`${name}.url`} label="URL" register={register} placeholder="https://" />
                     <Check name={`${name}.openNewWindow`} label="Abrir en nueva ventana" register={register} />
                     <Radio
                        label="Colores"
                        name={`${name}.styleOptions.buttonType`}
                        register={register}
                        defaultValue={field.styleOptions.buttonType}
                     >
                        <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                        <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                     </Radio>
                     <div className={`${watch(`${name}.styleOptions.buttonType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                        <Controller
                           name={`${name}.styleOptions.buttonTextColor`}
                           defaultValue={field.styleOptions.buttonTextColor}
                           render={({ field }) => (
                              <Color label="Color de texto" {...field} />
                           )}
                        />
                        <Controller
                           name={`${name}.styleOptions.buttonBackgroundColor`}
                           defaultValue={field.styleOptions.buttonBackgroundColor}
                           render={({ field }) => (
                              <ColorRGBA label="Color de fondo" {...field} />
                           )}
                        />
                        <Controller
                           name={`${name}.styleOptions.buttonBorderColor`}
                           defaultValue={field.styleOptions.buttonBorderColor}
                           render={({ field }) => (
                              <Color label="Color del borde" {...field} />
                           )}
                        />
                     </div>
                  </div>
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default CallToActionForm;
