import { useFormContext } from "react-hook-form";
import enums from "../../../constants/enums";
import ButtonForm from "../forms/ButtonForm";
import CallToActionForm from "../forms/CallToActionForm";
import GalleryForm from "../forms/GalleryForm";
import HeaderForm from "../forms/HeaderForm";
import HotelListForm from "../forms/HotelListForm";
import IconListForm from "../forms/IconListForm";
import ImageForm from "../forms/ImageForm";
import RowForm from "../forms/RowForm";
import TextForm from "../forms/TextForm";
import VideoForm from "../forms/VideoForm";

const FormList = ({field, item, name}) => {
      const { control, watch } = useFormContext();
      const accountCode = watch('account.code');
      const common = { name, field, control, accountCode };

      switch (field.type) {
         case enums.elementType.TEXT:
            return (
               <TextForm {...common} />
            );
         case enums.elementType.IMAGE:
            return (
               <ImageForm {...common} />
            );
         case enums.elementType.GALLERY:
            return (
               <GalleryForm {...common} />
            );
         case enums.elementType.CALL_TO_ACTION:
            return (
               <CallToActionForm {...common} />
            );
         case enums.elementType.BUTTON:
            return (
               <ButtonForm {...common} />
            );
         case enums.elementType.VIDEO:
            return (
               <VideoForm {...common} />
            );
         case enums.elementType.ICON_LIST:
            return (
               <IconListForm {...common} />
            );
         case enums.elementType.HEADER:
            return (
               <HeaderForm {...common} />
            );
         case enums.elementType.HOTEL_LIST:
            return (
               <HotelListForm {...common} />
            );
         case enums.elementType.ROW:
            return (
               <>
                  <div className={item && item.children && item.children.some(item => item.active) ? "hidden" : "block"}>
                     <RowForm {...common} />
                  </div>
                  {watch(`${name}`).children?.map((child, index) => {
                     return (
                        <div key={`${child.id ? child.id : `${field.id}-${index}`}`} className={item.children[index] && item.children[index].active ? "block" : "hidden"} >
                           <FormList field={child} item={item?.children[index]} name={`${name}.children.${index}`} />
                        </div>
                     );
                  })}
               </>
            );
         default:
            break;
      }
      return null;
};

export default FormList;
