import { Editor, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import componentUtils from '../../../utils/componentUtils';
import 'draft-js/dist/Draft.css';
import './Text.css';
import enums from '../../../constants/enums';


var INLINE_STYLES = [
   { label: 'Bold', style: 'BOLD' },
   { label: 'Italic', style: 'ITALIC' },
   { label: 'Underline', style: 'UNDERLINE' },
   { label: 'Monospace', style: 'CODE' },
];

const BLOCK_TYPES = [
   { label: 'H1', style: 'header-one' },
   { label: 'H2', style: 'header-two' },
   { label: 'H3', style: 'header-three' },
   { label: 'H4', style: 'header-four' },
   { label: 'H5', style: 'header-five' },
   { label: 'H6', style: 'header-six' },
   { label: 'Blockquote', style: 'blockquote' },
   { label: 'UL', style: 'unordered-list-item' },
   { label: 'OL', style: 'ordered-list-item' },
   { label: 'Code Block', style: 'code-block' },
];

const StyleButton = ({ onToggle, style, label, active }) => {
   const onToggleEvent = (event) => {
      event.preventDefault();
      onToggle(style)
   };
   let className = "flex items-center justify-center cursor-pointer pr-3 py-1";
   if (active) {
      className += " text-indigo-400";
   }
   return (
      <span onMouseDown={onToggleEvent} className={className}>
         {
            label
         }
      </span>
   )
};

const InlineStyleControls = ({ editorState, onToggle }) => {
   const currentStyle = editorState.getCurrentInlineStyle();

   return (
      <>
         {INLINE_STYLES.map((type) => (
            <StyleButton
               key={type.label}
               active={currentStyle.has(type.style)}
               label={type.label}
               onToggle={onToggle}
               style={type.style}
            />
         ))}
      </>
   );
};

const BlockStyleControls = ({ editorState, onToggle }) => {
   const selection = editorState.getSelection();
   const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

   return (
      <>
         {BLOCK_TYPES.map((type) => (
            <StyleButton
               key={type.label}
               active={type.style === blockType}
               label={type.label}
               onToggle={onToggle}
               style={type.style}
            />
         ))}
      </>
   )
};

const Text = ({ active, value, onChange, styleOptions }) => {
   const { type, textColor, backgroundColor, fontSize, fontSizeCode } = styleOptions
   const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
         onChangeEvent(newState);
         return true;
      }
      return false;
   };

   const toggleBlockType = (blockType) => {
      onChangeEvent(RichUtils.toggleBlockType(value, blockType));
   };

   const toggleInlineStyle = (inlineStyle) => {
      onChangeEvent(RichUtils.toggleInlineStyle(value, inlineStyle));
   };

   const getBlockStyle = (block) => {
      switch (block.getType()) {
         case 'blockquote': return 'RichEditor-blockquote';
         default: return null;
      }
   };

   const styleMap = {
      'CODE': {
         //backgroundColor: 'rgba(0, 0, 0, 0.05)',
         fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
         fontSize: 16,
         padding: 2,
      },
   };

   const onChangeEvent = (contentState) => {
      onChange(contentState);
   };

   const getTextStyle = () => {
      let result = null;
      if (type === enums.styleType.CUSTOM) {
         result = {
            style: {
               backgroundColor,
               color: textColor,
               fontSize: componentUtils.getFontSize(fontSizeCode, fontSize)
            }
         };
      }
      return result;
   };

   if (!active) {
      return (
         <div
            className="hover:bg-gray-100 p-4 cursor-pointer border border-dashed border-gray-200 py-4 px-2 itm-text-rich"
            {...getTextStyle()}
         >
            <div dangerouslySetInnerHTML={{ __html: stateToHTML(value.getCurrentContent()) }} />
         </div>
      );
   }

   return (
      <div className="border border-indigo-600">
         <div className="bg-gray-200 py-2 px-4 flex flex-wrap">
            <BlockStyleControls
               editorState={value}
               onToggle={toggleBlockType}
            />
            <InlineStyleControls
               editorState={value}
               onToggle={toggleInlineStyle}
            />
         </div>
         <div className="RichEditor-editor py-4 px-2" {...getTextStyle()}>
            <Editor
               handleKeyCommand={handleKeyCommand}
               editorState={value}
               onChange={onChangeEvent}
               blockStyleFn={getBlockStyle}
               customStyleMap={styleMap}
            />
         </div>
      </div >
   );
;
}

export default Text;
