import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import classNames from "classnames";

const DropdownContext = createContext({});
const useDropdownContext = () => {
   const context = useContext(DropdownContext);
   if (!context) {
      throw new Error(
         `Compound components cannot be rendered outside the Component`,
      );
   }
   return context;
};

const DropdownButton = ({ children, onClose, className }) => {
   const [show, setShow] = useState(false);
   const dropdownRef = useRef();
   const buttonRef = useRef();
   const prevState = useRef();
   const toggle = () => {
      setShow(!show)
   };

   useEffect(() => {
      if (prevState.current && !show) {
         if (onClose && typeof onClose === "function") {
            onClose();
         }
      }
      prevState.current = show;

   }, [show, onClose]);

   const close = useCallback(() => {
      setShow(false);
   }, [setShow]);

   useClickOutside(dropdownRef, close);
   const containerClassName = classNames(className, "relative");
   return (
      <DropdownContext.Provider value={{ show, toggle, dropdownRef, buttonRef }}>
         <div className={containerClassName}>
            {children}
         </div>
      </DropdownContext.Provider>
   );
};

const Button = ({ children, className, disabled }) => {
   const { toggle, buttonRef } = useDropdownContext(DropdownContext);
   let buttonClassName = classNames(className, "flex items-center leading-6 py-1.5 px-3 border rounded-md font-medium focus:outline-none disabled:opacity-50 border-gray-300  text-gray-700 bg-white hover:bg-gray-100")
   return (
      <button type="button" onClick={toggle} className={buttonClassName} ref={buttonRef} disabled={disabled}>
         {children}
         <div className="ml-2">
            <div className="invisible inline-block max-w-0">.</div>
            <div className="inline-block border-4 border-transparent" style={{ borderTopColor: '#000', marginBottom: "-0.125rem" }} />
         </div>
      </button>
   );
};

const Dropdown = ({ children, offset }) => {
   const { show, dropdownRef, buttonRef } = useDropdownContext(DropdownContext);
   if (!show) {
      return null;
   }
   return (
      <div className="absolute left-0 z-10" ref={dropdownRef} style={{ top: `${(buttonRef?.current?.offsetHeight) + offset}px` }}>
         {children}
      </div>
   )
};
Dropdown.defaultProps = {
   offset: 0
};

DropdownButton.Button = Button;
DropdownButton.Dropdown = Dropdown;

export default DropdownButton;
