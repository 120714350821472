import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAccountListWrapper } from "../../access/account";
import { getChannelListWrapper } from "../../access/channel";
import { getEventListWrapper } from "../../access/event";
import accountListActions from "../../redux/actions/accountList";
import eventListActions from "../../redux/actions/eventList";
import channelListActions from "../../redux/actions/channelList";

const HeaderTitle = ({ item = {} }) => {
   const dispatch = useDispatch();
   const accountListWrapper = useSelector((state) => state.accountListWrapper);
   const eventListWrapper = useSelector((state) => state.eventListWrapper);
   const channelListWrapper = useSelector((state) => state.channelListWrapper);

   useEffect(() => {
      return () => {
         dispatch(eventListActions.clear());
      };
   }, [dispatch]);

   useEffect(() => {
      if (accountListWrapper.isPristine) {
         dispatch(accountListActions.fetching());
         async function getAsync() {
            const data = await getAccountListWrapper();
            dispatch(accountListActions.get(data));
         }
         getAsync();
      }
   }, [dispatch, accountListWrapper]);

   useEffect(() => {
      if (eventListWrapper.isPristine) {
         dispatch(eventListActions.fetching());
         async function getAsync() {
            const data = await getEventListWrapper({
               account: item.account.code,
            });
            dispatch(eventListActions.get(data));
         }
         if (item.account && item.account.code !== "") {
            getAsync();
         }
      }
   }, [dispatch, eventListWrapper, item.account]);

   useEffect(() => {
      if (channelListWrapper.isPristine) {
         dispatch(channelListActions.fetching());
         async function getAsync() {
            const data = await getChannelListWrapper({
               account: item.account.code,
            });
            dispatch(channelListActions.get(data));
         }
         if (item.account && item.account.code !== "") {
            getAsync();
         }
      }
   }, [dispatch, channelListWrapper, item.account]);

   const getAccount = () => {
      if (item) {
         const account = accountListWrapper.items.find(
            (i) => i.code === item.account?.code
         );
         if (account) {
            return account.name;
         }
         return item.account?.code;
      }
      return null;
   };

   const getEvent = () => {
      if (item && item.eventCode !== "") {
         const event = eventListWrapper.items.find(
            (i) => i.code === item.eventCode
         );
         const eventName = event ? event.name : item.event;
         if (eventName) {
            return (
               <div className="text-gray-500 text-sm">
                  Evento: <b>{eventName}</b>
               </div>
            );
         }
      }
      return null;
   };

   const getChannel = () => {
      if (item && item.channelId !== "") {
         const channel = channelListWrapper.items.find(
            (i) => i.id === parseInt(item.channelId)
         );
         if (channel) {
            return (
               <div className="text-gray-500 text-sm">
                  Canal:{" "}
                  <b>
                     {channel ? (
                        <>{`${channel.name} [${channel.currency}]${
                           channel.cc ? " [CC]" : ""
                        }`}</>
                     ) : (
                        item.channelId
                     )}
                  </b>
               </div>
            );
         }
      }
      return null;
   };

   return (
      <div className="flex items-center p-4">
         <div>
            <Link to="/" className="px-3 text-gray-400 inline-block mr-4">
               <svg
                  className="h-6 w-6"
                  viewBox="0 0 341.333 341.333"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
               >
                  <polygon points="341.333,149.333 81.707,149.333 200.853,30.187 170.667,0 0,170.667 170.667,341.333 200.853,311.147 81.707,192 341.333,192" />
               </svg>
            </Link>
         </div>
         <div>
            <div className="font-bold">
               {item.id ? "Editar página" : "Nueva página"}
            </div>
            <div className="flex gap-x-2">
               <div className="text-gray-500 text-sm">
                  Cuenta: <b>{getAccount()}</b>
               </div>
               {getEvent()}
               {getChannel()}
            </div>
         </div>
      </div>
   );
};

export default HeaderTitle;
