import { Auth } from "aws-amplify";
import qs from "qs";
import { transfromLandingToData } from "../utils/landingUtils";

export const getLandingListWrapper = async (request) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages${qs.stringify(request, { addQueryPrefix: true })}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    });
    const response = await data.json();
    return response;
};

export const getLandingWrapper = async (id) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    });
    const response = await data.json();
    return transfromLandingToData(response);
};

export const createLanding = async (request) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(request)
    });
    const response = await data.json();
    return response;
};


export const saveLanding = async (request) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(request)
    });
    const response = await data.json();
    return response;
};

export const deleteLanding = async (id) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        }
    });
    return data.ok;
};

export const duplicateLanding  = async (id) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(`${process.env.REACT_APP_API}/pages/${id}/duplicate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`
        }
    });
    return data.ok;
};