import classNames from "classnames";
import { formatImage } from "../../../utils/imageUtils";

const Gallery = ({ images, active }) => {
   const className = classNames("w-full h-full border p-2", {
      "border-indigo-600": active,
      "border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });

   const drawGallery = () => {
      const group = [...Array(5).keys()].map(i => {
         if (images[i]) {
            return images[i];
         } else {
            return { empty: true };
         }
      });
      return group.map((image, index) => {
         if (image.empty) {
            return (
               <div key={`e-${index}`} className="w-full h-full bg-gray-200" style={{minHeight: '2rem'}} />
            );
         }
         let className = "relative";
         let src = formatImage(image, {w: 300, h: 215, fit: 'crop'});
         if (index === 0) {
            className = "relative col-span-2 row-span-2";
            src = formatImage(image, { w: 600, h: 430, fit: 'crop' });
         }

         return (
            <div key={`${image.name}-${index}`} className={className} >
               <img src={src} alt={image.name} className="w-full h-full object-fill" />
               {index === 4 && images.length > 5 && (
                  <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center text-white text-3xl">
                     {
                        `+ ${images.length - 5}`
                     }
                  </div>
               )}
            </div>
         );
      });
   };

   return (
      <div className={className}>
         {(!images || images.length === 0) ? (
            <div className="flex justify-center align-middle px-4 py-8 text-gray-200">
               <svg className="h-32 w-32" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 469.333 469.333" fill="currentColor">
                  <polygon points="319.253,198.08 260.587,273.6 218.667,223.253 160,298.667 394.667,298.667" />
                  <path d="M42.667,85.333H0v341.333c0,23.573,19.093,42.667,42.667,42.667H384v-42.667H42.667V85.333z" />
                  <path d="M426.667,0H128c-23.573,0-42.667,19.093-42.667,42.667v298.667C85.333,364.907,104.427,384,128,384h298.667
                  c23.573,0,42.667-19.093,42.667-42.667V42.667C469.333,19.093,450.24,0,426.667,0z M426.667,341.333H128V42.667h298.667V341.333z
                  "/>
               </svg>
            </div>
         ) : (
               <div className="grid grid-cols-4 grid-rows-2 gap-2">
                  {drawGallery()}
               </div>
            )}

      </div>
   );
};

export default Gallery;