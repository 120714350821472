import { Button } from "./";
import enums from "../../constants/enums";

const BackgroundSelector = ({ label, value, onChange }) => {
   return (
      <div className="mb-4">
         <div className="mb-2">{label}</div>
         <Button.Group spaced={false} className="w-full flex">
            <Button
               className={`flex-1 ${value === enums.backgroundType.COLOR ? 'bg-gray-200' : ''}`}
               onClick={() => onChange(enums.backgroundType.COLOR)}
            >
               Color
            </Button>
            <Button
               className={`flex-1 ${value === enums.backgroundType.IMAGE ? 'bg-gray-200' : ''}`}
               onClick={() => onChange(enums.backgroundType.IMAGE)}
            >
              Imagen
            </Button>
         </Button.Group>
      </div>
   );
};

export default BackgroundSelector;