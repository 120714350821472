import classNames from "classnames";
import './Video.css';

const Video = ({ active, url, title }) => {
   const className = classNames("border p-2", {
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });

   const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]+).*/;
   const result = url ? url.match(regex) : null;

   return (
      <div className={className}>
         {result && result[1] ? (
            <div className="relative">
               <div className="embed-responsive embed-responsive-4by3">
                  <iframe src={`https://www.youtube.com/embed/${result[1]}`}
                     className="embed-responsive-item"
                     title={title}
                     frameBorder="0"
                     allowFullScreen />
               </div>
               <div className="absolute inset-0 z-10"></div>
            </div>
         ) : (
            <div className="flex justify-center align-middle px-4 py-8 text-gray-200">
               <svg className="h-32 w-32" viewBox="-21 -117 682.66672 682" fill="currentColor">
                  <path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" />
               </svg>
            </div>
         )}
      </div >
   );
};

export default Video;
