import { useState } from "react";
import { Button, Modal } from "../../common";
import classNames from 'classnames';
import enums from "../../../constants/enums";

const Preview = ({ mode, url, onClose, onChange }) => {
   const [showLoading, setShowLoading] = useState(true);
   return (
      <Modal className="md:w-4/5 md:h-4/5 flex flex-col bg-gray-800">
         <div className="p-4 flex justify-between items-center bg-white">
            <h2>Vista Previa</h2>
            <Button.Group spaced={false}>
               {[
                  { code: enums.previewMode.DESKTOP, name: 'Escritorio' },
                  { code: enums.previewMode.TABLET, name: 'Tableta' },
                  { code: enums.previewMode.MOBILE, name: ' Móvil' }
               ].map(previewMode => (
                  <Button
                     key={previewMode.code}
                     className={mode === previewMode.code ? 'bg-gray-200' : ''}
                     onClick={() => onChange(previewMode.code)}
                  >
                     {previewMode.name}
                  </Button>
               ))}
            </Button.Group>
            <button type="button" className="focus:outline-none" onClick={onClose}>
               <svg className="h-8 w-8" viewBox="0 0 24 24" stroke="currentColor"  ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
         </div>
         <div className={classNames("h-full w-full self-center py-4 relative", {
            "w-full": mode === enums.previewMode.DESKTOP,
            "max-w-screen-md": mode === enums.previewMode.TABLET,
            "max-w-sm": mode === enums.previewMode.MOBILE
         })}>
            {showLoading && (
               <div className="absolute inset-0 flex justify-center items-center">
                  <div className="text-white">
                     <svg className="animate-spin h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                     </svg>
                  </div>
               </div>
            )}
            <iframe src={url} title="preview" className="w-full h-full" onLoad={() => setShowLoading(false)} />
         </div>
      </Modal>
   )
};

export default Preview;
