import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { useContext } from "react";
import { Route } from "react-router";
import { AuthContext } from "../../App";

function PrivateRoute({ children, ...rest }) {
    const { user } = useContext(AuthContext);

    return (
        <Route {...rest} render={() => {
            return user
                ? children
                : (
                    <AmplifyAuthenticator>
                        <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
                    </AmplifyAuthenticator>
                )
        }} />
    );
};

export default PrivateRoute;
