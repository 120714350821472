import './form.css';

const Check = ({ name, label, register }) => {
   return (
      <div className="form-check mb-4">
         <input type="checkbox" className="form-check-input outline-none" {...register(name)} id={name} />
         {label && (
            <label htmlFor={name} className="inline-block">
               {
                  label
               }
            </label>
         )}
      </div>
   );
};

export default Check;
