import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { useEffect } from "react";

function useAuth({ setUser, clearUser }) {
    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            if (event === 'signIn') {
                setUser(data);
            }
            if (event === 'signOut') {
                setUser(null);
            }
        });
        checkUser({ setUser });
    }, [setUser]);
}

async function checkUser({ setUser }) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
    } catch (error) {
        console.log(error);
    }
}

export default useAuth;