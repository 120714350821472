import { useState, useReducer, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import './PhotoSelector.css';
import enums from "../../constants/enums";
import classNames from "classnames";
import { Button } from ".";
import { DDThumbnail } from "./Thumbnail";
import { fetching, get, clear } from "../../redux/actions/imageList";
import { getImageListWrapper, uploadFile } from "../../access/image";
import { formatImage } from "../../utils/imageUtils";
import { useDropzone } from 'react-dropzone';
import LazyImg from "./LazyImg";
import Modal from "./Modal";

// Modal.setAppElement('#root');

const reducer = (state, action) => {
   const { image, newIndex, prevIndex, single } = action.data || {};
   let selected = null;
   switch (action.type) {
      case 'select':
         selected = single ? {} : Object.assign({}, state.selected);
         const size = single ? 0 : state.size;
         selected[image.id] = state.selected[image.id] ? null : size + 1;
         return Object.assign({}, state, { selected, size: size + 1 });
      case 'move':
         selected = Object.assign({}, state.selected);
         Object.keys(state.selected).forEach((key) => {
            if (state.selected[key] === prevIndex) {
               selected[key] = newIndex;
            }
            if (state.selected[key] === newIndex) {
               selected[key] = prevIndex;
            }
         });
         return Object.assign({}, state, { selected });
      default:
         return state;
   }
};

const PhotoSelector = ({ aspectRatio, selected, onClose, onConfirm, single, maxSize, customFormat, folder }) => {
   const [isUploading, setIsUploading] = useState(false);
   const initialState = {
      selected: selected || {},
      size: Object.keys(selected || {}).length
   };
   const imageDispatch = useDispatch();
   const init = useCallback(() => {
      imageDispatch(fetching());
      async function getAsync() {
         const data = await getImageListWrapper(folder);
         imageDispatch(get(data));
      };
      getAsync();
   }, [imageDispatch, folder]);

   useEffect(() => {
      init();
      return () => {
         imageDispatch(clear());
      }
   }, [init, imageDispatch]);

   const imageListWrapper = useSelector(state => state.imageListWrapper);
   const [state, dispatch] = useReducer(reducer, initialState);
   const [query, setQuery] = useState("");
   const [errors, setErrors] = useState([]);

   const onDrop = useCallback((accepted, rejected) => {
      setErrors([]);
      if (accepted && accepted.length > 0) {
         const promises = [];
         setIsUploading(true);
         accepted.forEach(file => {
            promises.push(uploadFile(file, folder));
         });
         Promise.allSettled(promises)
            .then(() => {
               setIsUploading(false);
               init();
            });
      }
      if (rejected && rejected.length > 0) {
         setErrors(rejected.map(rej => `
            Nombre: ${rej.file.name},
            Tamaño: ${rej.file.size / 1000000} Mb
            ${rej.errors ? `, Razón: ${rej.errors.map(e => e.message).join(', ')}` : ''}
         `));
      }
   }, [init, folder]);
   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: "image/*",
      maxSize
   });
   const imageClassName = classNames("itm-photoPicker-img-container", {
      "itm-photoPicker-img-container--wide": aspectRatio === enums.aspectRatio.WIDE,
      "itm-photoPicker-img-container--portrait": aspectRatio === enums.aspectRatio.PORTRAIT
   });
   const gridClassName = classNames("grid gap-4", {
      "grid-cols-photo-wide": aspectRatio === enums.aspectRatio.WIDE,
      "grid-cols-photo-square": aspectRatio === enums.aspectRatio.SQUARE || aspectRatio === enums.aspectRatio.NONE,
      "grid-cols-photo-portrait": aspectRatio === enums.aspectRatio.PORTRAIT,
   });
   const selectImage = (image) => {
      dispatch({ type: 'select', data: { image, single } })
   };
   const moveImage = (newIndex, prevIndex) => {
      dispatch({ type: 'move', data: { newIndex, prevIndex } })
   };
   const onConfirmEvent = () => {
      const items = imageListWrapper.items
         .map((item) => {
            if (state.selected[item.id]) {
               item.order = state.selected[item.id];
               return item;
            }
            return null;
         })
         .filter(item => item != null)
         .concat()
         .sort((a, b) => a.order - b.order);
      onConfirm(items);
   };

   const onCloseEvent = () => {
      //hola mundo
      onClose();
   };

   const getFormat = () => {
      let format = {};
      switch (aspectRatio) {
         case enums.aspectRatio.WIDE:
            format = { w: 240, h: 135, fit: "crop", quality: 'low' };
            break;
         case enums.aspectRatio.PORTRAIT:
            format = { w: 180, h: 240, fit: "crop", quality: 'low' };
            break;
         case enums.aspectRatio.SQUARE:
            format = { w: 200, h: 200, fit: "pad", quality: 'low' };
            break;
         case enums.aspectRatio.NONE:
            format = { w: 200, h: 200, fit: "pad", quality: 'low' };
            if (customFormat) {
               format = customFormat;
            }
            break;
         default:
            format = { w: 200, h: 200, fit: "crop", quality: 'low' };
            break;
      }
      return format;
   }

   return (
      <Modal className="itm-modal-photoSelector" onClose={onCloseEvent}>
         <Modal.Header>
            Seleccionar imagen <span className="text-gray-400 text-sm">({folder})</span>
         </Modal.Header>
         <Modal.Body>
            <div className="mb-4">
               <input
                  className="form-control"
                  placeholder="Filtrar por nombre de archivo..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)} />
            </div>
            {errors && errors.length > 0 && (
               <div className="bg-yellow-100 border rounded-sm border-yellow-400 p-4 mb-4 text-yellow-600 relative">
                  No se pueden subir los siguientes archivos:
                  <ul className="ml-8 list-disc">
                     {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                     ))}
                  </ul>
                  <div className="absolute top-4 right-4 cursor-pointer" onClick={() => setErrors([])}>
                     <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.667 298.667" fill="currentColor">
                        <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333" />
                     </svg>
                  </div>
               </div>
            )}
            <div className="overflow-auto h-80 shadow-inner">
               <div className={gridClassName}>
                  <div {...getRootProps({
                     className: "relative flex flex-col justify-between align-middle text-center border border-dashed border-gray-400 p-4 cursor-pointer"
                  })}>
                     {isUploading && (
                        <div className="absolute inset-0 bg-gray-300 bg-opacity-75 flex items-center justify-center">
                           <svg className="animate-spin -ml-1 mr-3 h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                           </svg>
                        </div>
                     )}
                     <input {...getInputProps()} />
                     <div className="text-lg overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {isDragActive
                           ? 'Suelta la imagen aquí... '
                           : 'Arrastrar imágenes o dar click para seleccionar'}
                     </div>
                     <Button>
                        Seleccionar
                     </Button>
                     <div className="text-sm text-gray-400">
                        Tamaño máximo: {maxSize / 1000000} MB
                     </div>
                  </div>
                  {imageListWrapper.isFetching && (
                     Array
                        .from({ length: 5 }, (_, i) => i)
                        .map(item => (
                           <div key={item}>
                              <div className={imageClassName}></div>
                           </div>
                        ))
                  )}
                  {imageListWrapper.items
                     .filter(image =>
                        image &&
                        (query === "" || new RegExp(query).test(image.name))
                     ).map(image => (
                        <div key={image.id} className="relative cursor-pointer" onClick={() => selectImage(image)}>
                           <div className={`itm-photoPicker-img-decoration ${state.selected[image.id] ? "itm-photoPicker-img-decoration--selected" : ""}`}>
                              {state.selected[image.id] && (
                                 <span className="itm-photoPicker-img-check">
                                    <svg className="h-4 w-4" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 375.147 375.147" fill="currentColor" >
                                       <polygon points="344.96,44.48 119.147,270.293 30.187,181.333 0,211.52 119.147,330.667 375.147,74.667" />
                                    </svg>
                                 </span>
                              )}
                           </div>
                           <div className={imageClassName}>
                              <LazyImg src={formatImage(image, getFormat(image))} alt={image.name} />
                           </div>
                           <div className="itm-photoPicker-img-description">{image.name}</div>
                        </div>
                     ))}
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <DndProvider backend={HTML5Backend}>
               <div className="grid gap-4 grid-cols-photo-selected">
                  {imageListWrapper.items
                     .map((image) => {
                        if (state.selected[image.id]) {
                           return {
                              order: state.selected[image.id],
                              value: image
                           };
                        }
                        return null;
                     })
                     .filter(item => item != null)
                     .concat()
                     .sort((a, b) => a.order - b.order)
                     .map((item) => (
                        <DDThumbnail
                           key={item.value.id}
                           image={item.value}
                           index={item.order}
                           onDelete={selectImage}
                           onMove={moveImage}
                           format={getFormat(item.value)}
                        />
                     ))
                  }
               </div>
            </DndProvider>
            <div className="text-right">
               <Button.Group>
                  <Button onClick={onCloseEvent}>Cancelar</Button>
                  <Button onClick={onConfirmEvent} bsStyle="success">Seleccionar</Button>
               </Button.Group>
            </div>
         </Modal.Footer>

      </Modal>
   );
};

PhotoSelector.defaultProps = {
   aspectRatio: enums.aspectRatio.WIDE,
   maxSize: 10000000
};

export default PhotoSelector;
