import { createContext, createElement, useContext } from "react";
import classNames from "classnames";

const RadioContext = createContext();

function useRadioContext() {
   const context = useContext(RadioContext);
   if (!context) {
      throw new Error(
         `Radio components cannot be rendered outside the Radio component`,
      )
   }
   return context;
};

const Radio = ({ children, name, label, inline, register, defaultValue, component, className }) => {
   return (
      <RadioContext.Provider value={{ name, register, inline, defaultValue }}>
         {createElement(component, {
            children: [
               label ? (
                  <span key={`${name}-label`} className="mb-2 inline-block">{label}</span>

               ) : null,
               ...children
            ],
            className
         })}
      </RadioContext.Provider>
   );
};

const Option = ({ children, value, className }) => {
   const { name, register, inline, defaultValue } = useRadioContext();
   const labelClassName = classNames(className, "relative pl-5 cursor-pointer",
      {
         "inline-block mr-4": inline,
         "block": !inline,
      });
   return (
      <label className={labelClassName}>
         <input
            type="radio"
            value={value}
            {...register(name)}
            defaultChecked={defaultValue === value}
            className="absolute -ml-5 mt-1 w-4 h-4 cursor-pointer" />
         <span className="font-light">{children}</span>
      </label>
   );
};
Option.defaultProps = {
   className: "mb-2"
}

Radio.Option = Option;

Radio.defaultProps = {
   inline: false,
   component: "div",
   className: "mb-4"
};

export default Radio;
