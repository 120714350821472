import { types } from "../actions/channelList";

const initialState = {
   items: [],
   isFetching: false,
   isPristine: true,
};

export default function channelListReducer(state = initialState, action) {
   switch (action.type) {
      case types.GET_LIST_SUCCESS:
         return {
            ...state,
            items: action.data,
            isFetching: false,
            isPristine: false,
         };
      case types.GET_LIST_REQUEST:
         return {
            ...state,
            isFetching: true,
            isPristine: false,
         };
      case types.CLEAR_LIST:
         return initialState;
      default:
         return state;
   }
}
