import classNames from 'classnames';
import { createContext, forwardRef, useContext } from 'react';
import './Button.css';

const ButtonGroupContext = createContext({
   spaced: false
});

const Group = forwardRef(({ spaced, children, className }, ref) => {
   return (
      <ButtonGroupContext.Provider value={{ spaced }}>
         <div className={`itm-btn-group ${className}`} ref={ref}>
            {children}
         </div>
      </ButtonGroupContext.Provider>

   )
})

const Button = ({ children, type, bsStyle, onClick, className, buttonStyle, isLoading, hasAnimation, disabled }) => {
   const { spaced } = useContext(ButtonGroupContext);
   let buttonClassName = classNames(className, "leading-6 py-1.5 px-3 border rounded-md font-medium focus:outline-none disabled:opacity-50", {
      "border-gray-300  text-gray-700 bg-white hover:bg-gray-100": bsStyle === "default",
      "border-green-400 text-white bg-green-400 hover:bg-green-500": bsStyle === "success",
      "border-gray-300 text-gray-700 bg-gray-100 hover:bg-gray-200": bsStyle === "gray",
      "mr-2 last:mr-0": spaced,
      "itm-btn": !spaced,
      "relative transition-all": hasAnimation,
      "pr-8": isLoading
   });
   return (
      <button
         {...buttonStyle && { style: buttonStyle }}
         className={buttonClassName}
         type={type}
         onClick={onClick}
         disabled={disabled}
      >
         {children}
         {isLoading && (
            <div className="absolute right-2 bottom-2.5">
               <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
               </svg>
            </div>
         )}
      </button>
   );
};

Group.defaultProps = {
   spaced: true,
   className: ''
};

Button.defaultProps = {
   bsStyle: 'default',
   type: 'button',
   disabled: false
};

Button.Group = Group;

export default Button;
