import { Storage } from "aws-amplify";

export const getImageListWrapper = async (accountCode) => {
    try {
        const response = await Storage.list(`${accountCode || process.env.REACT_APP_bucket_folder}/`);
        const result = response
            .sort((a, b) => b.lastModified - a.lastModified)
            .map(image => {
                if (image.size > 0 && image.key.indexOf("/") >= 0) {
                    return {
                        id: image.eTag,
                        name: image.key.split("/").slice(-1)[0],
                        path: image.key
                    }
                }
                return null;
            })
            .filter(image => image !== null);
        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const uploadFile = async (file, accountCode) => {
    try {
        await Storage.put(`${accountCode || process.env.REACT_APP_bucket_folder}/${file.name}`, file, {
            contentType: file.type
        });
    } catch (error) {
        console.log('Error uploading file: ', error);
    }
};
