import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import enums from "../../../constants/enums";
import { Button, DropdownButton, MultiCheck } from "../../common";
import { Input, Radio } from "../../fields";
import { ReactComponent as Search } from "../../icons/search.svg";
import { getAccountListWrapper } from '../../../access/account';
import accountListActions from "../../../redux/actions/accountList";
import './filters.css';

const Filters = ({onSubmit, defaultValues}) => {
   const { handleSubmit, register, watch, setValue, control } = useForm({
      mode: "onTouched"
   });
   const dispatch = useDispatch();
   const accountListWrapper = useSelector(state => state.accountListWrapper);
   const options = [
      { value: enums.status.PUBLISHED, label: "Publicada" },
      { value: enums.status.DRAFT, label: "Borrador" },
      { value: enums.status.INACTIVE, label: "Inactiva" }
   ];

   useEffect(() => {
      if(accountListWrapper.isPristine){  
         dispatch(accountListActions.fetching());
         async function getAsync () {
            const data = await getAccountListWrapper();
            dispatch(accountListActions.get(data));
         };
         getAsync();
      }
   }, [dispatch, accountListWrapper]);

   useEffect(() => {
      setValue("locale", defaultValues.locale || "ALL");
      setValue("query", defaultValues.query || "");
      if(typeof defaultValues.status === "string" && defaultValues.status !== ""){
         setValue("status", [defaultValues.status]);
      }else{
         setValue("status", defaultValues.status || []);
      }
      if(typeof defaultValues.account === "string" && defaultValues.account !== ""){
         setValue("account", [defaultValues.account]);
      }else{
         setValue("account", defaultValues.account || []);
      }
   }, [defaultValues, setValue]);

   const onSubmitEvent = (data) => {
      const { status, locale, query, ...rest } = data;
      const result = { ...rest };
      if(status !== enums.status.ALL){
         result.status = status;
      }
      if(locale !== "ALL"){
         result.locale = locale;
      }
      if(query && query.trim() !== ""){
         result.query = query.trim();
      }
      onSubmit(result);
   };

   const getLanguageName = (code) => {
      switch (code) {
         case "es-mx":
            return "Español";
         case "en-us":
            return "Inglés";
         case "ALL":
            return "Todos";
         default:
            return code;
      }
   };

   const drawCount = (name, total, fetching, word = "Todas") => {
      if(fetching){
         return "...";
      }
      const sizeSelected = watch(name)?.length;
      if(sizeSelected>0){
         return `${sizeSelected} de ${total}`
      }
      return word;
   };

   const languageOptions = ["es-mx", "en-us"];

   return (
      <div className="my-6">
         <form onSubmit={handleSubmit(onSubmitEvent)}>
            <div className="flex justify-between items-center">
               <div className="flex">
                  <DropdownButton onClose={handleSubmit(onSubmitEvent)} className="mr-2 last:mr-0">
                     <DropdownButton.Button>Estatus: {drawCount("status", options.length, false, "Todos")}</DropdownButton.Button>
                     <DropdownButton.Dropdown>
                        <div className="bg-white border shadow-sm py-1">
                           <ul>
                              <Controller
                                 control={control}
                                 name="status"
                                 render={({ field: { ref, ...rest } }) => (
                                    <MultiCheck {...rest} >
                                       {options.map((option) => (
                                          <li key={option.value} className="hover:bg-gray-100">
                                             <MultiCheck.Check value={option.value} label={option.label} />
                                          </li>
                                       ))}
                                    </MultiCheck>
                                 )}
                              />
                           </ul>
                        </div>
                     </DropdownButton.Dropdown>
                  </DropdownButton>
                  <DropdownButton onClose={handleSubmit(onSubmitEvent)} className="mr-2 last:mr-0">
                     <DropdownButton.Button>Idioma: {getLanguageName(watch("locale"))}</DropdownButton.Button>
                     <DropdownButton.Dropdown>
                        <div className="bg-white border shadow-sm">
                           <Radio name="locale" register={register} component="ul" >
                              <li className="px-4 my-2">
                                 <Radio.Option value={"ALL"} >Todos</Radio.Option>
                              </li>
                              {languageOptions.map((option) => (
                                 <li key={option} className="px-4 my-2">
                                    <Radio.Option value={option} >{getLanguageName(option)}</Radio.Option>
                                 </li>
                              ))}
                           </Radio>

                        </div>
                     </DropdownButton.Dropdown>
                  </DropdownButton>
                  <DropdownButton onClose={handleSubmit(onSubmitEvent)} className="mr-2 last:mr-0">
                     <DropdownButton.Button disabled={accountListWrapper.isPristine || accountListWrapper.isFetching}>
                           Cuentas: {drawCount("account", accountListWrapper.items.length, accountListWrapper.isPristine || accountListWrapper.isFetching)}
                        </DropdownButton.Button>
                     <DropdownButton.Dropdown>
                        <div className="bg-white border shadow-sm py-1 overflow-y-auto" style={{maxHeight: '50rem'}}>
                           <ul>
                              <Controller
                                 control={control}
                                 name="account"
                                 render={({ field: { ref, ...rest } }) => (
                                    <MultiCheck {...rest} >
                                       {accountListWrapper.items.map((option) => (
                                          <li key={option.code} className="hover:bg-gray-100">
                                             <MultiCheck.Check value={option.code} label={option.name} />
                                          </li>
                                       ))}
                                    </MultiCheck>
                                 )}
                              />
                           </ul>
                        </div>
                     </DropdownButton.Dropdown>
                  </DropdownButton>
               </div>
               <div>
                  <div className="flex items-center itm-search-field">
                     <Input name="query" register={register} noMargin />
                     <Button bsStyle="gray" type="submit" className="flex items-center">
                        <i className="invisible inline-block max-w-0">.</i>
                        <div className="h-4 w-4 inline-block">
                           <Search />
                        </div>
                     </Button>
                  </div>
               </div>
            </div>
         </form>

      </div>
   );
};

export default Filters;
