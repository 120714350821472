import { createTypes, asyncAction } from 'redux-action-types';

export const types = createTypes('landing', asyncAction('GET'), 'CLEAR');

export const get = (data) => ({
    type: types.GET_SUCCESS,
    data,
});

export const fetching = () => ({
    type: types.GET_REQUEST
});

export const clear = () => ({
    type: types.CLEAR
});
