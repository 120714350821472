import { useFieldArray } from "react-hook-form";
import { useReducer, useEffect, useCallback } from "react";
import classNames from "classnames";
import NewElement from "./NewElement";
import enums from "../../../constants/enums";
import Element from "./Element";
import { getComponent } from "../../../utils/elementUtils";

const reducer = (state, action) => {
   const newElements = state.children.slice();
   const { index, type, children } = action.data || {};
   const clear = (item) => {
      item.active = false;
      if (item.children && item.children.length >= 0) {
         item.children.map(clear);
      }
   };
   switch (action.type) {
      case 'add':
         newElements.map(item => item.active = false);
         newElements.push({
            type,
            active: true,
            children: []
         });
         return Object.assign({}, state, { children: newElements });
      case 'select':
         newElements.map(item => item.active = false);
         newElements[index].active = true;
         return Object.assign({}, state, { children: newElements });
      case 'remove':
         newElements.splice(index, 1);
         return Object.assign({}, state, { children: newElements });
      case 'children_update':
         newElements[index].children = children;
         return Object.assign({}, state, { children: newElements });
      case 'clear':
         newElements.map(clear);
         return Object.assign({}, state, { children: newElements });
      default:
         return state;
   }
};

const Row = ({ active, index, name, onUpdate, children, size, control }) => {
   const initialState = {
      children: children.map(child => ({ type: child.type, active: false }))
   };
   const [group, dispatch] = useReducer(reducer, initialState);
   const { fields, append, remove } = useFieldArray({ 
      control,
      name: `${name}.${index}.children`
   });
   useEffect(() => {
      if (!active) {
         dispatch({ type: 'clear' });
      }
   }, [active]);

   useEffect(() => {
      onUpdate(index, group.children.slice());
   }, [index, onUpdate, group]);

   const onSelect = (index) => {
      dispatch({ type: "select", data: { index } });
   };

   const onUpdateCurrent = useCallback((index, children) => {
      dispatch({ type: "children_update", data: { index, children: children.slice() } });
   }, []);

   const createComponent = (type) => {
      append(getComponent(type));
      dispatch({ type: "add", data: { active: true, type } });
   };

   const removeComponent = (index) => {
      remove(index);
      dispatch({ type: "remove", data: { index } });
   };

   const className = classNames("flex border", {
      "flex-col": size === enums.columnTypes.W100,
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed": !active
   });
   let sizes = [];
   switch (size) {
      case enums.columnTypes.W50_W50:
         sizes = ["1/2", "1/2"];
         break;
      case enums.columnTypes.W70_W30:
         sizes = ["8/12", "4/12"];
         break;
      case enums.columnTypes.W30_W70:
         sizes = ["4/12", "8/12"];
         break;
      case enums.columnTypes.W33_W33_W33:
         sizes = ["4/12", "4/12", "4/12"];
         break;
      default:
         sizes = ["full"];
         break;
   }
   return (
      <div className={className}>
         {fields.map((component, field_index) => (
            <div
               key={component.id}
               className={`w-${sizes[field_index] || 'full'}`}
            >
               <Element
                  component={component}
                  index={field_index}
                  name={`${name}.${index}.children`}
                  active={group.children && group.children[field_index] && group.children[field_index].active}
                  onSelect={onSelect}
                  onUpdate={onUpdateCurrent}
                  onRemove={removeComponent}
                  componentSize={sizes[field_index]}
                  className={`${size === enums.columnTypes.W100 ? "mt-6" : "my-6"} mx-3`}
               />
            </div>
         ))}
         {(fields.length < sizes.length || size === enums.columnTypes.W100) && (
            <div className={`w-${sizes[fields.length] || 'full'}`}>
               <NewElement onCreate={createComponent} internal />
            </div>
         )}
      </div>
   );
};

export default Row;
