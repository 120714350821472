import classNames from "classnames";

const Select = ({ register, name, label, children, defaultValue, required, hasError, disabled }) => {
   const labelClassName = classNames("mb-2 inline-block", {
      "text-red-400": hasError
   });
   const selectClassName = classNames("form-control", {
      "form-input-error" : hasError
   });

   return (
      <div className="mb-4">
         {label && (
            <label htmlFor={name} className={labelClassName}>
               {
                  label
               }
            </label>
         )}
         <select
            className={selectClassName}
            {...register ? register(name, { required }) : { name }}
            {...defaultValue && { defaultValue }}
            {...disabled && { disabled: true }}
         >
            {children}
         </select>
      </div>
   );
};

const Option = ({ value, children }) => {
   return (
      <option value={value}>
         {children}
      </option>

   );
};

Select.Option = Option;

const SelectLoading = ({ label }) => (
   <div className="mb-4">
      {label && (
         <label className="mb-2 inline-block">
            {
               label
            }
         </label>
      )}
      <select className="form-control" disabled>
         <option>Cargando...</option>
      </select>
   </div>
);

export { SelectLoading };

export default Select;
