import { Controller } from "react-hook-form";
import enums from "../../../constants/enums";
import { Color, Input, Photo, Radio } from "../../fields";
import { ConnectForm } from "../Landing";

const HeaderForm = ({ name, field }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Header</h3>
         </div>
         <ConnectForm>
            {({ register, formState: { errors }, watch }) => (
               <>
                  <input type="hidden" {...register(`${name}.type`)} />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Logo</div>
                     <Controller
                        name={`${name}.image`}
                        render={({ field: { ref, ...rest } }) => (
                           <Photo
                              {...rest} 
                              aspectRatio={enums.aspectRatio.NONE}
                              single
                              excludeHotel
                              customFormat={{
                                 w: 250,
                                 h: 40,
                                 fit: "fit"
                              }}
                           />
                        )}
                     />
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Información de contacto</div>
                     <Radio
                        name={`${name}.contactType`}
                        register={register}
                        defaultValue={field.contactType}
                     >
                        <Radio.Option value={enums.styleType.DEFAULT}>Default</Radio.Option>
                        <Radio.Option value={enums.styleType.CUSTOM}>Personalizar</Radio.Option>
                     </Radio>
                     <div className={`${watch(`${name}.contactType`) === enums.styleType.CUSTOM ? "block" : "hidden"}`}>
                        <Input
                           name={`${name}.phone`}
                           label="Teléfono"
                           register={register}
                           placeholder="9999999999"
                           {...watch(`${name}.contactType`) === enums.styleType.CUSTOM && {
                              validationOptions: {
                                 required: "required"
                              }
                           }}
                           errors={errors}
                        /> 
                        <Input
                           name={`${name}.email`}
                           label="Email"
                           register={register}
                           placeholder="example@mail.com"
                           type="email"
                           {...watch(`${name}.contactType`) === enums.styleType.CUSTOM && {
                              validationOptions: {
                                 required: "required",
                                 pattern: {
                                    //eslint-disable-next-line
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Revisa que el formato del email esta correcto (example@mail.com)"
                                 }
                              }
                           }}
                           errors={errors}
                        />
                     </div>
                  </div>
                  <hr />
                  <div className="p-4">
                     <div className="uppercase mb-4 font-semibold">Visibilidad</div>
                     <Controller
                        name={`${name}.styleOptions.backgroundColor`}
                        defaultValue={'#FFFFFF'}
                        render={({ field }) => (
                           <Color label="Color de fondo" {...field} />
                        )}
                     />
                     <Controller
                        name={`${name}.styleOptions.textColor`}
                        defaultValue={'#000000'}
                        render={({ field }) => (
                           <Color label="Color de texto" {...field} />
                        )}
                     />
                  </div>
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default HeaderForm;
