import classNames from "classnames";
import { Button } from "../../common";
import { useFieldArray } from "react-hook-form";
import Icon from "./Icon";
import enums from "../../../constants/enums";

const IconList = ({ active, name, control, size, styleOptions }) => {
   const { fields, append, remove } = useFieldArray({
      control,
      name: name
   });

   const addIcon = () => {
      append({});
   }

   const className = classNames("border p-2", {
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });
   const gridClassName = classNames("grid gap-4", {
      "grid-cols-3": size === enums.columnTypes.W33_W33_W33,
      "grid-cols-2": size === enums.columnTypes.W50_W50,
      "grid-cols-4": size === enums.columnTypes.W25_W25_W25_W25 
   })
   return (
      <div className={className}>
         <div className={gridClassName}>
            {fields.map((icon, index) => (
               <Icon
                  key={icon.id}
                  value={icon}
                  name={`${name}.${index}`}
                  onRemove={() => remove(index)}
                  styleOptions={styleOptions}
               />
            ))}
            {active && (
               <div className="flex flex-col justify-end p-4">
                  <Button onClick={addIcon} className="flex justify-center">
                     <div className="py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357 357" fill="currentColor" className="h-4 w-4">
                           <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
                        </svg>
                     </div>
                  </Button>
               </div>
            )}
         </div>
      </div>
   );
};

export default IconList;
