import { useState, forwardRef, useRef, useEffect } from "react";
import classNames from "classnames";
import enums from "../../constants/enums";

const StyledInput = forwardRef(({ alignment, value, className, onChange, onBlur, hasValueClassName, emptyClassName, textStyle }, ref) => {
   const [edit, setEdit] = useState(false);
   const inputRef = useRef(null);
   const onKeyDown = (event) => {
      if (event.key === 'Enter') {
         inputRef.current.blur();
         setEdit(false);
      }
   };
   const onBlurEvent = () => {
      setEdit(false);
      onBlur();
   };
   useEffect(() => {
      if (edit && inputRef.current !== null) {
         inputRef.current.select();
      }
   }, [edit, inputRef]);

   const textClassName = classNames(className, { // "text-5xl font-light leading-tight"
      [`${hasValueClassName}`]: value, // "text-white"
      [`${emptyClassName}`]: !value //"text-gray-400"
   });
   const inputClassName = classNames(textClassName,
      "bg-opacity-10 bg-gray-800 w-full -m-2 p-2", {
      "text-center": alignment === enums.alignment.CENTER,
      "text-right": alignment === enums.alignment.RIGHT
   });
   const { color, ...rest } = textStyle || {};

   return (
      <>
         {!edit && (
            <div
               className={`${textClassName} hover:bg-black hover:bg-opacity-5`}
               onClick={() => setEdit(true)}
            >
               {value ? (
                  <span {...textStyle && { style: textStyle }}>
                     {value}
                  </span>
               ) : (
                  <span {...rest && { style: rest }}>Comienza a escribir...</span>
               )}
            </div>
         )}
         {edit && (
            <div>
               <input
                  type="text"
                  placeholder="Comienza a escribir..."
                  defaultValue={value}
                  className={inputClassName}
                  onChange={onChange}
                  onBlur={onBlurEvent}
                  onKeyDown={onKeyDown}
                  ref={(e) => {
                     ref(e);
                     inputRef.current = e;
                  }}
                  {...textStyle && {
                     style:
                        textStyle
                  }}
               />
            </div>
         )}
      </>
   );
});

StyledInput.defaultProps = {
   hasValueClassName: "text-gray-800",
   emptyClassName: "text-gray-800"
}

export default StyledInput;
