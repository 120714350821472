import { useEffect } from "react";
import { Controller, useWatch, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAccountListWrapper } from "../../../access/account";
import { getEventListWrapper } from "../../../access/event";
import { getChannelListWrapper } from "../../../access/channel";
import enums from "../../../constants/enums";
import accountListActions from "../../../redux/actions/accountList";
import eventListActions from "../../../redux/actions/eventList";
import channelListActions from "../../../redux/actions/channelList";
import Select, { SelectLoading } from "../../fields/Select";
import { ReactComponent as Done } from "../../icons/done.svg";
import classNames from "classnames";
import { DateTime } from "luxon";
import { MultiSelect } from "../../fields";
import { createFilter } from "react-select";

const Check = () => (
   <div className="absolute top-4 -left-4">
      <div className=" rounded-full bg-green-500 h-8 w-8 text-white p-2">
         <Done />
      </div>
   </div>
);

const LayoutButton = ({ selected, children, onClick, label, hasError }) => {
   const className = classNames("h-56 border relative mb-2", {
      "border-green-500": selected,
      "border-red-400": hasError,
   });
   return (
      <button
         className={`focus:outline-none ${selected ? "font-bold" : ""}`}
         onClick={onClick}
      >
         <div className={className}>
            {selected && <Check />}
            {children}
         </div>
         <div className={hasError ? "text-red-400" : ""}>{label}</div>
      </button>
   );
};

const CreateForm = () => {
   const dispatch = useDispatch();
   const {
      register,
      formState: { errors },
      control,
      setValue,
   } = useFormContext();
   const account = useWatch({ control, name: "account" });

   const accountListWrapper = useSelector((state) => state.accountListWrapper);
   const eventListWrapper = useSelector((state) => state.eventListWrapper);
   const channelListWrapper = useSelector((state) => state.channelListWrapper);

   useEffect(() => {
      if (accountListWrapper.isPristine) {
         dispatch(accountListActions.fetching());
         async function getAsync() {
            const data = await getAccountListWrapper();
            dispatch(accountListActions.get(data));
         }
         dispatch(accountListActions.fetching());
         getAsync();
      }
   }, [dispatch, accountListWrapper]);

   useEffect(() => {
      if (account) {
         setValue("event", null);
         async function getAsync() {
            const data = await getEventListWrapper({
               account,
            });
            dispatch(eventListActions.get(data));
         }
         dispatch(eventListActions.fetching());
         getAsync();
      }
      return () => {
         dispatch(eventListActions.clear());
      };
   }, [dispatch, account, setValue]);

   useEffect(() => {
      if (account) {
         setValue("channel", null);
         async function getAsync() {
            const data = await getChannelListWrapper({
               account,
            });
            dispatch(channelListActions.get(data.filter((d) => !d.cc)));
         }
         dispatch(channelListActions.fetching());
         getAsync();
         return () => {
            dispatch(channelListActions.clear());
         };
      }
      return () => {
         dispatch(channelListActions.clear());
      };
   }, [dispatch, account, setValue]);

   const eventLabel = (
      <>
         Evento&nbsp;<span className="text-gray-400">(opcional)</span>
      </>
   );

   const channelLabel = (
      <>
         Canal&nbsp;<span className="text-gray-400">(opcional)</span>
      </>
   );

   const mapEventOption = (event) => {
      const getType = () => {
         switch (event.type) {
            case enums.eventType.WEDDING:
               return "Boda";
            case enums.eventType.GROUP:
               return "Grupo";
            default:
               return event.type;
         }
      };

      const getDate = () => {
         const startDate = DateTime.fromISO(event.startDate).setLocale("es-mx");
         const endDate =
            event.endDate && DateTime.fromISO(event.endDate).setLocale("es-mx");
         const diff = endDate ? startDate.diff(endDate) : null;
         if (!diff || diff.milliseconds === 0) {
            return startDate.toFormat("dd MMM yyyy");
         }
         if (startDate.month === endDate.month) {
            return `${startDate.toFormat("dd")} - ${endDate.toFormat(
               "dd MMM yyyy"
            )}`;
         }
         return `${startDate.toFormat("dd MMM yyyy")} - ${endDate.toFormat(
            "dd MMM yyyy"
         )}`;
      };

      const getLabel = () => {
         return (
            <>
               {event.name}
               <div className="text-gray-400 text-sm flex gap-x-1">
                  <span>{getType()}</span>&#183;
                  <span>{getDate()}</span>
               </div>
            </>
         );
      };

      return {
         label: getLabel(),
         value: event.code,
         name: event.name,
      };
   };

   const mapChannelOption = (channel) => {
      const getLabel = () => {
         return (
            <>
               {channel.name}
               <div className="text-gray-400 text-sm flex gap-x-1">
                  <span>{channel.currency}</span>
                  {channel.cc && (
                     <>
                        &#183;
                        <span>CC</span>
                     </>
                  )}
               </div>
            </>
         );
      };
      return {
         label: getLabel(),
         value: channel.id,
         name: channel.name,
      };
   };

   return (
      <>
         <div className="md:flex gap-x-8">
            <div className="md:w-1/2 w-full">
               {accountListWrapper.isFetching ? (
                  <SelectLoading label="Cuenta" />
               ) : (
                  <>
                     <Select
                        register={register}
                        label="Cuenta"
                        name="account"
                        required
                        hasError={errors.account}
                     >
                        <Select.Option value={""}>Seleccionar</Select.Option>
                        {accountListWrapper.items.map((item) => (
                           <Select.Option key={item.code} value={item.code}>
                              {item.name}
                           </Select.Option>
                        ))}
                     </Select>
                     {errors.account && (
                        <div className="text-red-400 mb-4 text-sm">
                           Elegir una opción
                        </div>
                     )}
                  </>
               )}
            </div>
            <div className="md:w-1/2 w-full">
               {channelListWrapper.isFetching ? (
                  <SelectLoading label={channelLabel} />
               ) : (
                  <Controller
                     name="channel"
                     render={({ field }) => (
                        <MultiSelect
                           label={channelLabel}
                           field={field}
                           isMulti={false}
                           disabled={channelListWrapper.items.length === 0}
                           placeholder={
                              channelListWrapper.isPristine ||
                              (!channelListWrapper.isFetching &&
                                 channelListWrapper.items.length > 0)
                                 ? "Seleccionar"
                                 : "No hay canales"
                           }
                           options={channelListWrapper.items.map(
                              mapChannelOption
                           )}
                           getValue={(options) => {
                              let result = null;
                              if (field && field.value) {
                                 result = options.find(
                                    (option) => option.value === field.value
                                 );
                              }
                              return result;
                           }}
                           filterOption={createFilter({
                              matchFrom: "any",
                              stringify: (option) => `${option.data.name}`,
                           })}
                        />
                     )}
                  />
               )}
               {eventListWrapper.isFetching ? (
                  <SelectLoading label={eventLabel} />
               ) : (
                  <Controller
                     name="event"
                     render={({ field }) => (
                        <MultiSelect
                           label={eventLabel}
                           field={field}
                           isMulti={false}
                           disabled={eventListWrapper.items.length === 0}
                           placeholder={
                              eventListWrapper.isPristine ||
                              (!eventListWrapper.isFetching &&
                                 eventListWrapper.items.length > 0)
                                 ? "Seleccionar"
                                 : "No hay eventos"
                           }
                           options={eventListWrapper.items.map(mapEventOption)}
                           getValue={(options) => {
                              let result = null;
                              if (field && field.value) {
                                 result = options.find(
                                    (option) => option.value === field.value
                                 );
                              }
                              return result;
                           }}
                           filterOption={createFilter({
                              matchFrom: "any",
                              stringify: (option) => `${option.data.name}`,
                           })}
                        />
                     )}
                  />
               )}
            </div>
         </div>
         <div>
            <Controller
               control={control}
               name="layout"
               rules={{
                  validate: (value) => {
                     return value !== null && value !== undefined;
                  },
               }}
               render={({ field: { value, onChange } }) => {
                  const hasError = errors.layout?.type === "validate";
                  return (
                     <>
                        <div className="mb-4">
                           <label
                              className={`inline-block mb-4 ${
                                 hasError ? "text-red-400" : ""
                              }`}
                           >
                              Elige una plantilla
                           </label>
                           <div className="grid grid-flow-row grid-cols-2 gap-8">
                              <LayoutButton
                                 selected={value === enums.layout.FULL_WIDTH}
                                 onClick={() =>
                                    onChange(enums.layout.FULL_WIDTH)
                                 }
                                 hasError={hasError}
                                 label="Una columna"
                              >
                                 <div className="bg-gray-100 p-4 flex">
                                    <div className="w-8/12 px-2 h-16">
                                       <div className="bg-gray-200 h-4 mb-2"></div>
                                       <div className="bg-gray-200 w-6/12 h-3"></div>
                                    </div>
                                    <div className="w-4/12 px-2">
                                       <div className="bg-gray-200 p-2">
                                          <div className="bg-white h-3 mb-2"></div>
                                          <div className="bg-white h-3 mb-2"></div>
                                          <div className="bg-gray-300 w-1/2 h-3"></div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="text-center uppercase text-xs pt-4 text-gray-400 font-bold">
                                    Contenido
                                 </div>
                              </LayoutButton>
                              <LayoutButton
                                 selected={value === enums.layout.TWO_COLUMNS}
                                 onClick={() =>
                                    onChange(enums.layout.TWO_COLUMNS)
                                 }
                                 hasError={hasError}
                                 label="Dos columnas"
                              >
                                 <div className="bg-gray-100 p-4 flex">
                                    <div className="w-full px-2 h-16">
                                       <div className="bg-gray-200 w-8/12 h-4 mb-2"></div>
                                       <div className="bg-gray-200 w-6/12 h-3"></div>
                                    </div>
                                 </div>
                                 <div className="flex p-4">
                                    <div className="w-8/12">
                                       <div className="text-center uppercase text-xs text-gray-400 font-bold">
                                          Contenido
                                       </div>
                                    </div>
                                    <div className="w-4/12 px-2">
                                       <div className="bg-gray-200 p-2 mb-2">
                                          <div className="bg-white h-3 mb-2"></div>
                                          <div className="bg-white h-3 mb-2"></div>
                                          <div className="bg-gray-300 w-1/2 h-3"></div>
                                       </div>
                                    </div>
                                 </div>
                              </LayoutButton>
                           </div>
                        </div>
                        {hasError && (
                           <div className="text-red-400 mb-4 text-sm">
                              Elegir una opción
                           </div>
                        )}
                     </>
                  );
               }}
            />
         </div>
      </>
   );
};

export default CreateForm;
