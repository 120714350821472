import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../icons/close.svg';

const Badge = ({ messages, onClose }) => {
   const isVisible = messages && messages.length > 0;

   const containerClassName = classNames("transition-height max-w-screen-xl px-4 flex justify-between mx-auto items-stretch", {
      "h-20": isVisible,
      "h-0": !isVisible
   });

   return (
      <div className="absolute left-0 top-0 bg-green-600 text-white w-full z-50">
         <div className={containerClassName}>
            <div className="flex items-center">
               {messages.map(message => (
                  <div className="text-xl">
                     {message}
                  </div>
               ))}
            </div>
            <div className="flex items-center">
               {isVisible && (
                  <button type="button" className="h-full px-8 focus:outline-none" onClick={onClose}>
                     <div className="w-4 h-4"><CloseIcon /></div>
                  </button>
               )}
            </div>
         </div>
      </div>
   );
};

export default Badge;
