import { useEffect, useState } from "react";

const LazyImg = ({ src, alt, ...rest }) => {
   const [imageSrc, setImageSrc] = useState(null);
   const [imageRef, setImageRef] = useState();

   useEffect(() => {
      let didCancel = false;
      let observer = null;
      if (imageRef) {
         if (IntersectionObserver) {
            observer = new IntersectionObserver(
               (entries) => {
                  entries.forEach((entry) => {
                     if (
                        !didCancel &&
                        (entry.intersectionRatio > 0 || entry.isIntersecting)
                     ) {
                        setImageSrc(src);
                        observer.unobserve(imageRef);
                     }
                  });
               },
               {
                  threshold: 0.01,
                  rootMargin: "75%"
               }
            );
            observer.observe(imageRef);
         } else {
            setImageSrc(src);
         }
      }
      return () => {
         didCancel = true
         // on component unmount, we remove the listner
         if (observer && observer.unobserve) {
            observer.unobserve(imageRef)
         }
      }
   }, [src, imageRef]);

   return (
      <img
         ref={setImageRef}
         src={imageSrc}
         alt={alt}
         {...rest}
      />
   );

}

export default LazyImg;
