import enums from '../constants/enums';
import { EditorState } from 'draft-js';

function getComponent(type) {
   switch (type) {
      case enums.elementType.TEXT:
         return {
            type,
            content: EditorState.createEmpty(),
            styleOptions: {
               textType: enums.styleType.DEFAULT,
               textColor: "#000000",
               backgroundColor: "#FFFFFF",
               fontSizeCode: enums.sizeCode.MD,
               fontSize: 15
            }
         };
      case enums.elementType.ROW:
         return { type, children: [], size: enums.columnTypes.W50_W50, notContained: false };
      case enums.elementType.IMAGE:
         return {
            type,
            image: null,
            styleOptions: {
               silhouette: enums.silhouette.RECTANGLE
            }
         };
      case enums.elementType.CALL_TO_ACTION:
         return {
            type,
            image: null,
            styleOptions: {
               alignment: enums.alignment.CENTER,
               textColor: "#000000",
               backgroundColor: "#F3F3F3",
               backgroundType: enums.backgroundType.COLOR,
               buttonType: enums.styleType.DEFAULT,
               buttonTextColor: "#374151",
               buttonBackgroundColor: "#FFFFFF",
               buttonBorderColor: "#E5E7E1"
            },
            url: ''
         };
      case enums.elementType.BUTTON:
         return {
            type,
            styleOptions: {
               alignment: enums.alignment.CENTER,
               buttonType: enums.styleType.DEFAULT,
               buttonTextColor: "#374151",
               buttonBackgroundColor: "#FFFFFF",
               buttonBorderColor: "#E5E7E1"
            }
         }
      case enums.elementType.ICON_LIST:
         return {
            type,
            size: enums.columnTypes.W33_W33_W33,
            styleOptions: {
               textType: enums.styleType.DEFAULT,
               textColor: "#000000",
               fontSizeCode: enums.sizeCode.MD,
               fontSize: 15,
               iconColor: "#000000",
               iconSizeCode: enums.sizeCode.MD,
               iconSize: 15
            }
         }
      case enums.elementType.HEADER: 
         return {
            type,
            contactType: enums.styleType.DEFAULT, 
            styleOptions: {
               textColor: "#000000",
               backgroundColor: "#FFFFFF",
            }
         };
      default:
         return { type };
   }
};

export {
   getComponent
};