import NewElement from "./NewElement";
import Element, { DDElement } from './Element';
import { getComponent } from '../../../utils/elementUtils';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useState } from "react";


const ElementList = ({ components, group, onSelect, onCreate, onUpdate, onRemove, onMove, name, excludedTypes }) => {
   const { fields, append, remove, swap } = components;
   const [isMoving, setIsMoving] = useState(false);

   const createComponent = (type) => {
      append(getComponent(type));
      onCreate(type);
   };

   const removeComponent = (index) => {
      remove(index);
      onRemove(index);
   };

   const moveComponent = (a, b) => {
      swap(a, b);
      onMove(a, b);
   };

   const onRowMove = (index, isUp) => {
      if(isUp && index> 0){
         moveComponent(index, index - 1);
      }
      if(!isUp && index < (fields.length - 1)){
         moveComponent(index, index + 1);
      }
   };

   const onSelectEvent = (index) => {
      setIsMoving(false);
      onSelect(index);
   };

   return (
      <>
         <DndProvider backend={HTML5Backend}>
            {fields.map((component, index) => {
               if(excludedTypes && excludedTypes.length > 0 && excludedTypes.indexOf(component.type) >= 0){
                  return null;
               }
               const commonProps = {
                  key: component.id,
                  component,
                  index,
                  length: fields.length,
                  name,
                  active: group.children && group.children[index] && group.children[index].active,
                  onSelect: onSelectEvent,
                  onUpdate,
                  onRowMoveClick: onRowMove,
                  onRemove: removeComponent
               };
               return isMoving ? (
                  <DDElement
                     {...commonProps}
                     onFreeMoveClick={() => setIsMoving(false)}
                     onMove={moveComponent}
                     onDrop={() => setIsMoving(false)}
                  />
               ) : (
                  <Element
                     {...commonProps}
                     onFreeMoveClick={() => setIsMoving(true)}
                  />
               )
            })}
         </DndProvider>
         <div className="h-56">
            <NewElement onCreate={createComponent} />
         </div>
      </>
   );
};

export default ElementList;
