import { combineReducers } from "redux";
import landingListWrapper from "./landingList";
import landingWrapper from "./landing";
import accountListWrapper from "./accountList";
import imageListWrapper from "./imageList";
import hotelListWrapper from "./hotelList";
import eventListWrapper from "./eventList";
import channelListWrapper from "./channelList";

export default combineReducers({
   landingListWrapper,
   landingWrapper,
   accountListWrapper,
   imageListWrapper,
   hotelListWrapper,
   eventListWrapper,
   channelListWrapper,
});
