import enums from "../constants/enums";

const getFontSize = (size, customSize) => {
   switch (size) {
      case enums.sizeCode.XS:
         return "x-small";
      case enums.sizeCode.SM:
         return "small"
      case enums.sizeCode.MD:
         return "unset";
      case enums.sizeCode.LG:
         return "large";
      case enums.sizeCode.XL:
         return "x-large"
      case enums.sizeCode.CUSTOM:
         return `${customSize}px`;
      default:
         return "medium";
   }
};

const result = {
   getFontSize
};

export default result;