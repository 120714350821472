import { StyledInput } from '../../fields';
import { useFormContext, Controller } from 'react-hook-form';
import componentUtils from '../../../utils/componentUtils';
import enums from '../../../constants/enums';
import IconSelector from '../../fields/IconSelector';

const Icon = ({ name, onRemove, styleOptions }) => {
   const { style, textColor, textSize, customTextSize, iconColor, iconSize } = styleOptions; // iconColor, iconSize, customIconSize
   const { control } = useFormContext();


   const getTextStyle = () => {
      let result = {};
      if (style === enums.styleType.CUSTOM) {
         result.color = textColor;
         result.fontSize = componentUtils.getFontSize(textSize, customTextSize);
      }
      return result;
   };

   const getIconStyle = () => {
      const result = {};
      result.color = iconColor;
      switch (iconSize) {
         case enums.sizeCode.SM:
            result.height = "2rem";
            result.width = "2rem";
            break;
         case enums.sizeCode.MD:
            result.height = "3rem";
            result.width = "3rem";
            break;
         case enums.sizeCode.LG:
            result.height = "4rem";
            result.width = "4rem";
            break;
         case enums.sizeCode.XL:
            result.height = "5rem";
            result.width = "5rem";
            break;
         case enums.sizeCode.XS:
            result.height = "1rem";
            result.width = "1rem";
            break;
         default:
            result.height = "3rem";
            result.width = "3rem"
            break;
      }
      return result;
   };

   return (
      <div className="flex items-center flex-col hover:bg-gray-50 p-4 itm-icon">
          <Controller
            control={control}
            name={`${name}.code`}
            render={({ field: { ref, ...rest } }) => (
               <IconSelector
                  {...rest}
                  iconStyle={getIconStyle()}
               />
            )}
         />
         <div className="w-full cursor-pointer">
            <Controller
               control={control}
               name={`${name}.text`}
               render={({ field }) => (
                  <StyledInput
                     placeholder="Comienza a escribir..."
                     className="text-center"
                     emptyClassName="text-gray-400"
                     {...field}
                     textStyle={getTextStyle()}
                  />
               )}
            />
         </div>
         <div className="absolute top-0 right-0 hidden itm-showOnHover">
            <button type="button" onClick={onRemove} className="p-2 text-gray-400 hover:text-gray-500">
               <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" fill="currentColor" >
                  <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                  <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333" />
               </svg>
            </button>
         </div>
       
      </div>
   );
};

export default Icon;
