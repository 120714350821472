import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import {
   Header,
   Body,
   Table,
   Navbar,
   MultiButton,
   Button,
   Modal,
   Badge,
} from "../common";
import {
   getLandingListWrapper,
   deleteLanding,
   duplicateLanding,
} from "../../access/landing";
import landingListActions from "../../redux/actions/landingList";
import { transformLandingToTableItem } from "../../utils/landingUtils";
import enums from "../../constants/enums";
import Preview from "./components/Preview";
import CreateForm from "./forms/CreateForm";
import { FormProvider, useForm } from "react-hook-form";
import Filters from "./forms/Filters";
import { ReactComponent as AddIcon } from "../icons/add.svg";

const Landings = () => {
   const history = useHistory();
   const location = useLocation();
   const dispatch = useDispatch();
   const [alert, setAlert] = useState(null);
   const [preview, setPreview] = useState(null);
   const [showCreate, setShowCreate] = useState(false);
   const [messages, setMessages] = useState([]);
   const createForm = useForm({
      account: "",
   });

   const getList = useCallback(
      (request) => {
         async function getAsync() {
            const data = await getLandingListWrapper(request);
            dispatch(landingListActions.get(data));
         }
         getAsync();
      },
      [dispatch]
   );

   const setPreviewMode = (mode) => {
      setPreview(Object.assign({}, preview, { mode }));
   };

   useEffect(() => {
      dispatch(landingListActions.fetching());
      getList(qs.parse(location.search, { ignoreQueryPrefix: true }));
      return () => {
         dispatch(landingListActions.clear());
      };
   }, [dispatch, getList, location.search]);

   const landingListWrapper = useSelector((state) => state.landingListWrapper);

   const data = useMemo(
      () => ({
         isFetching: landingListWrapper.isFetching,
         items: landingListWrapper.items.map(transformLandingToTableItem),
      }),
      [landingListWrapper]
   );

   const onMultiButtonClick = useCallback(
      (action) => {
         switch (action.code) {
            case "DELETE":
               setAlert({
                  show: true,
                  content: `Confirmar eliminación?`,
                  onSuccess: () => {
                     deleteLanding(action.id).then(() => {
                        getList();
                        setAlert(null);
                        messages.push(
                           "La página se ha eliminado correctamente"
                        );
                     });
                  },
               });
               break;
            case "DUPLICATE":
               setAlert({
                  show: true,
                  content: "Confirmar duplicación?",
                  onSuccess: () => {
                     duplicateLanding(action.id).then(() => {
                        getList();
                        setAlert(null);
                        messages.push(
                           "La página se ha duplicado correctamente"
                        );
                     });
                  },
               });
               break;
            case "VIEW":
               window.open(
                  `${process.env.REACT_APP_preview_site}/${action.accountCode}${
                     action.uri ? `/${action.uri}` : ""
                  }/${action.id}`
               );
               break;
            case "PREVIEW":
               setPreview({
                  show: true,
                  mode: enums.previewMode.DESKTOP,
                  url: `${process.env.REACT_APP_preview_site}/preview/${action.id}`,
               });
               break;
            default:
         }
      },
      [getList, messages]
   );

   const columns = useMemo(
      () => [
         {
            Header: "Nombre",
            accessor: "title",
            width: 300,
            Cell: ({ value, row: { original } }) => {
               return (
                  <Link
                     to={`/edit/${original.id}`}
                     className="hover:underline text-blue-600"
                  >
                     {value}
                  </Link>
               );
            },
         },
         {
            Header: "Estatus",
            accessor: "status",
            width: null,
            Cell: ({ value }) => {
               switch (value) {
                  case enums.status.PUBLISHED:
                     return (
                        <div className="text-green-700 font-bold">
                           Publicada
                        </div>
                     );
                  case enums.status.DRAFT:
                     return <div className="text-gray-400">Borrador</div>;
                  case enums.status.INACTIVE:
                     return <div className="text-yellow-500">Inactiva</div>;
                  default:
                     return <div className="text-gray-400">{value}</div>;
               }
            },
         },
         {
            Header: "Idioma",
            accessor: "language",
            width: null,
         },
         {
            Header: "Autor",
            accessor: "author",
            width: null,
         },
         {
            Header: "Cuenta / Hotel",
            accessor: "account",
            width: null,
            Cell: ({ value }) => (
               <>
                  <div>{value.name}</div>
                  {value.hotel && (
                     <div className="text-gray-400 text-sm">{value.hotel}</div>
                  )}
               </>
            ),
         },
         {
            Header: "Última edición",
            accessor: "lastModified",
            Cell: ({ value }) => {
               if (value) {
                  const date = DateTime.fromSeconds(value).setLocale("es-mx");
                  return (
                     <>
                        {date
                           .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                           .replace(/ de/g, "")}
                        <br />
                        <span className="text-gray-400 text-sm">
                           {date.toLocaleString(DateTime.TIME_24_SIMPLE)} hrs
                        </span>
                     </>
                  );
               }
               return null;
            },
         },
         {
            Header: "",
            accessor: "actions",
            width: null,
            overflowVisible: true,
            Cell: ({ value }) => {
               if (value) {
                  return (
                     <MultiButton
                        topOptions={value.top}
                        bottomOptions={value.bottom}
                        onClick={onMultiButtonClick}
                     >
                        <Link
                           className="inline-block leading-6 py-1.5 px-3 border rounded-md font-medium focus:outline-none disabled:opacity-50 border-gray-300 text-gray-700 bg-white hover:bg-gray-100 itm-btn"
                           to={`/edit/${value.main.id}`}
                        >
                           {value.main.name}
                        </Link>
                     </MultiButton>
                  );
               }
               return null;
            },
         },
      ],
      [onMultiButtonClick]
   );

   const onCreateSubmit = createForm.handleSubmit((data) => {
      const query = {
         layout: data.layout,
         ...(data.event && {
            eventCode: data.event,
         }),
         ...(data.channel && {
            channelId: data.channel,
         }),
      };
      history.push(`${data.account}/create?${qs.stringify(query)}`);
   });

   const onCloseCreate = () => {
      setShowCreate(false);
      createForm.reset();
   };

   const hideMessages = () => {
      setMessages([]);
   };

   const onFilter = (data) => {
      const query = qs.stringify(data, {
         arrayFormat: "comma",
         allowDots: true,
         encode: false,
      }); //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
      history.push(`?${query}`);
   };

   return (
      <>
         <Navbar />
         <Header title="Páginas">
            <Button
               className="flex items-center"
               onClick={() => setShowCreate(true)}
            >
               <div className="w-4 h-4 mr-2">
                  <AddIcon />
               </div>
               Agregar página
            </Button>
         </Header>
         <Body>
            <Filters
               onSubmit={onFilter}
               defaultValues={qs.parse(location.search, {
                  comma: true,
                  ignoreQueryPrefix: true,
               })}
            />
            <Table
               columns={columns}
               data={data.items}
               fetching={data.isFetching}
            />
            {alert && alert.show && (
               <Modal className="sm:w-96">
                  <Modal.Body>{alert.content}</Modal.Body>
                  <Modal.Footer>
                     <Button.Group>
                        <Button onClick={() => setAlert(null)}>Cancelar</Button>
                        <Button bsStyle="success" onClick={alert.onSuccess}>
                           Aceptar
                        </Button>
                     </Button.Group>
                  </Modal.Footer>
               </Modal>
            )}
            {preview && preview.show && (
               <Preview
                  mode={preview.mode}
                  url={preview.url}
                  onChange={setPreviewMode}
                  onClose={() => setPreview(null)}
               />
            )}
            {showCreate && (
               <FormProvider {...createForm}>
                  <Modal
                     className="xl:w-5/12 md:w-7/12"
                     onClose={onCloseCreate}
                  >
                     <Modal.Header>
                        <span className="text-lg">Nueva página</span>
                     </Modal.Header>
                     <Modal.Body>
                        <div className="px-4">
                           <CreateForm />
                        </div>
                     </Modal.Body>
                     <Modal.Footer>
                        <Button.Group spaced>
                           <Button onClick={onCloseCreate}>Cancelar</Button>
                           <Button bsStyle="success" onClick={onCreateSubmit}>
                              Continuar
                           </Button>
                        </Button.Group>
                     </Modal.Footer>
                  </Modal>
               </FormProvider>
            )}
            <Badge messages={messages} onClose={hideMessages} />
         </Body>
      </>
   );
};

export default Landings;
