import classNames from "classnames";
import Widget from "./Widget";
import enums from "../../../constants/enums";
import './Banner.css';
import { Controller, useFormContext } from "react-hook-form";
import { StyledInput } from "../../fields";
import { formatImage } from "../../../utils/imageUtils";
import ButtonComponent from "./ButtonComponent";

const Banner = ({ active, onSelect, widgetStyle, alignment, bannerImages, layout,watch }) => {
   const { control } = useFormContext();
   const className = classNames("bg-gray-200 cursor-pointer border", {
      "border-indigo-600": active,
      "border-transparent": !active
   });
   const contentClassName = classNames("mt-16 w-50", {
      "w-7/12": widgetStyle === enums.widgetStyle.VERTICAL,
      "widgetStyle": widgetStyle !== enums.widgetStyle.VERTICAL,
      "widgetStyle-right": alignment === enums.alignment.RIGHT,
      "widgetStyle-center": alignment === enums.alignment.CENTER,
   });
   const contentClassNameInput = classNames("font-light leading-tight", {
      "text-3xl": watch("banner.textSize") === enums.sizeCode.XS,
      "text-4xl": watch("banner.textSize") === enums.sizeCode.SM,
      "text-5xl": (watch("banner.textSize") === enums.sizeCode.MD || !watch("banner.textSize")),
      "text-6xl": watch("banner.textSize") === enums.sizeCode.LG,
      "text-7xl": watch("banner.textSize") === enums.sizeCode.XL,
   });
   const contentClassColorNameInput = watch("banner.textColor") && {
      color: watch("banner.textColor")
   };
   const contentBG = watch("banner.hasBackgroundColor") && {
      backgroundColor: watch("banner.backgroundColor")
   };
   
   const gButtom = () => {
      let component = null;
      if(watch("banner.hasButton"))
      {
         const name = "banner"
         const index = "button"
         const common = {
            active
         };
         const buttonWatchProps = {
            //alignment: watch(`${name}.${index}.styleOptions.alignment`),
            buttonStyle: watch(`${name}.${index}.buttonType`),
            buttonBgColor: watch(`${name}.${index}.backgroundColor`),
            buttonTextColor: watch(`${name}.${index}.textColor`),
            buttonBorderColor: watch(`${name}.${index}.borderColor`)
         };

         component = (<ButtonComponent
            name={`${name}.${index}.buttonText`}
            styleOptions={buttonWatchProps}
            {...common}
         />);
      }
      return component;
   }
   return (
      <div className={className} onClick={onSelect}>
         <div className="itm-banner">
            <div
               className="absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center"
               {...(bannerImages && bannerImages.length > 0) && ({
                  style:{
                     backgroundImage: `url("${ formatImage(bannerImages[0], { w: 998, h: 480, fit: 'crop'}) }")`
                  }
               })}
            />
            {layout !== enums.layout.TWO_COLUMNS && (
               <Widget style={widgetStyle} />
            )}
            <div className="px-6 flex absolute w-full">
               <div className={contentClassName} {...contentBG && { style: contentBG }}>
                  <Controller
                     control={control}
                     name="banner.title"
                     render={({ field }) => (
                        <StyledInput
                           alignment={alignment}
                           className={contentClassNameInput}
                           hasValueClassName="text-white"
                           textStyle={contentClassColorNameInput}
                           emptyClassName="text-gray-400"
                           {...field}
                        />
                     )}
                  />
                  {gButtom()}
                  
               </div>
            </div>
         </div>
      </div>
   );
};

export default Banner;
