import classNames from "classnames";
import { createContext, useContext } from "react";
import enums from "../../../constants/enums";

const LayoutContext = createContext({
   type: enums.layout.FULL_WIDTH
});

const Layout = ({type, className, children}) => {
   const layoutClassName = classNames(className, {
      "flex": type === enums.layout.TWO_COLUMNS
   })
   return (
      <LayoutContext.Provider value={{type}}>
         <div className={layoutClassName}>
            {children}
         </div>
      </LayoutContext.Provider>
   );
};

const Main = ({className, children}) => {
   const { type } = useContext(LayoutContext)
   const mainClassName = classNames(className, {
      "w-8/12": type === enums.layout.TWO_COLUMNS
   })
   return (
      <div className={mainClassName}>
         {children}
      </div>
   );
};

const Side = ({className, children}) => {
   const { type } = useContext(LayoutContext);
   const sideClassName = classNames(className, {
      "w-4/12": type === enums.layout.TWO_COLUMNS
   });
   return (
      <div className={sideClassName}>
         {children}
      </div>
   );
};

Layout.Main = Main;
Layout.Side = Side;

export default Layout;
