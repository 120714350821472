import { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App"
import Auth from "@aws-amplify/auth";

const Navbar = () => {
   const { user, setUser } = useContext(AuthContext);
   const trigger = useRef(null);
   const overlay = useRef(null);
   const [showProfileMenu, setShowProfileMenu] = useState(false);
   const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

   const toggleMobileMenu = () => {
      setMobileMenuIsOpen(!mobileMenuIsOpen);
   };

   const toggleProfileMenu = (event) => {
      event.stopPropagation();
      setShowProfileMenu(!showProfileMenu);
   };

   // const closeProfileMenu = (event) => {
   //    const isDescendant = overlay && overlay.current && overlay.current.contains(event.target);
   //    if (!isDescendant) {
   //       setShowProfileMenu(false);
   //    }
   // };

   const signOut = async () => {
      try {
         await Auth.signOut();
         setUser(null);
      } catch (error) {
         console.log('error signing out: ', error)
      }
   };

   return (
      <nav className="bg-green-100">
         <div className="max-w-screen-xl mx-auto px-2">
            <div className="relative flex items-center justify-between h-16">
               <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <button
                     type="button"
                     className="inline-flex items-center justify-center p-2 rounded-md text-green-400 hover:text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                     onClick={toggleMobileMenu}
                  >
                     {mobileMenuIsOpen ? (
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                     ) : (
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                     )}
                  </button>
               </div>
               <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                     <span className="block lg:hidden" >MKT</span>
                     <span className="hidden lg:block" >MKTium</span>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                     <div className="flex space-x-4">
                        <Link to="/" className="bg-green-700 text-white px-3 py-2 rounded-md font-medium" aria-current="page">Páginas</Link>
                     </div>
                  </div>
               </div>
               {user && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                     <div className="ml-3 relative">
                        <button
                           type="button"
                           className="flex items-center focus:outline-none"
                           ref={trigger}
                           onClick={toggleProfileMenu}>
                           <div className="bg-green-800 h-8 w-8 flex items-center justify-center text-white rounded-full">
                              {user.username.match(/\b\w/g).join('').toUpperCase()}
                           </div>
                           <div className="hidden md:block ml-2">
                              {user.username}
                           </div>
                        </button>
                        {showProfileMenu && (
                           <div
                              ref={overlay}
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                           >
                              <button className="block px-4 py-2 text-green-700 hover:bg-green-100" role="menuitem" onClick={signOut}>Sign out</button>
                           </div>
                        )}
                     </div>
                  </div>)}
            </div>
         </div>
         { mobileMenuIsOpen && (
            <div className="sm:hidden" id="mobile-menu">
               <div className="px-2 pt-2 pb-3 space-y-1">
                  <Link to="/" className="bg-green-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Páginas</Link>
                  {/* <a href="#" className="text-green-300 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a> */}
               </div>
            </div>
         )}
      </nav>

   )
}

export default Navbar;
