import classNames from 'classnames';
import validationUtils from '../../utils/validationUtils';
import './form.css';

const Input = ({ name, label, helpText, register, placeholder, width, type, defaultValue, className, validationOptions, errors, noMargin, inputGroup, inputGroupAling }) => {
   const hasError = validationUtils.getPropertyValue(errors, name);
   
   const drawError = () => {
      const error = validationUtils.getPropertyValue(errors, name);
      if (error) {
         return (
            <div className="text-red-400 text-sm -mt-2 mb-4">
               {error.type === 'required' ? (
                  "Campo requerido"
               ) : (
                  error.message
               )
               }
            </div>
         )
      }
   };

   const inputClassName = classNames(`form-control ${className ? className : "" }`, {
      "form-input-error": hasError
   });

   return (
      <>
         <div className={`${!noMargin?"mb-4":""}`}>
            {label && (
               <label htmlFor={name} className={`inline-block mb-2 ${hasError ? "text-red-400" : ""}`}>
                  {
                     label
                  }
               </label>
            )}
            <div className={`${inputGroup?"input-group":""}`}>

            <input
               type={type || "text"}
               className={inputClassName}
               placeholder={placeholder}
               {...register(name, validationOptions)}
               {...width && { style: { width } }}
               defaultValue={defaultValue}
               />
            {inputGroup && inputGroupAling === "right" && (
               <span className="input-group-text">{inputGroup}</span>
               )}
            {helpText && (
               <small className="text-gray-400 block my-2">
                  {
                     helpText
                  }
               </small>
            )}
            </div>
         </div>
         {drawError()}
      </>
   );
};
Input.defaultProps = {
   className: "", validationOptions: {},
   noMargin: false,
   inputGroup: null,
   inputGroupAling: "left"
};

export default Input;
