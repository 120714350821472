import { useReducer, useCallback, useState, useRef, useEffect } from "react";
import {
   useForm,
   FormProvider,
   useFormContext,
   useFieldArray,
} from "react-hook-form";
import Banner from "./components/Banner";
import { Button, HeaderTitle } from "../common";
import ElementList from "./components/ElementList";
import ConfigurationForm from "./forms/ConfigurationForm";
import BannerForm from "./forms/BannerForm";
import enums from "../../constants/enums";
import {
   getChildrenFromComponents,
   transformLandingFromData,
   getDefaultLanding,
} from "../../utils/landingUtils";
import Element from "./components/Element";
import NewElement from "./components/NewElement";
import { getComponent } from "../../utils/elementUtils";
import Preview from "./components/Preview";
import FormList from "./components/FormList";
import Layout from "./components/Layout";
import { ReactComponent as ExternalLink } from "../icons/external.svg";
import Widget from "./components/Widget";
import { useSelector } from "react-redux";

export const ConnectForm = ({ children }) => {
   const methods = useFormContext();
   return children({ ...methods });
};

const initialState = {
   banner: {
      active: false,
   },
   children: [],
};

const reducer = (state, action) => {
   const newElements = state.children.slice();
   const { index, type, banner, children, new_index } = action.data || {};
   const clear = (item) => {
      item.active = false;
      if (item.children && item.children.length >= 0) {
         item.children.map(clear);
      }
   };
   switch (action.type) {
      case "add":
         newElements.map((item) => (item.active = false));
         newElements.push({
            type,
            active: true,
            children: [],
         });
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: false },
         });
      case "select":
         newElements.map((item) => (item.active = false));
         newElements[index].active = true;
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: false },
         });
      case "banner":
         newElements.map((item) => (item.active = false));
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: banner.active },
         });
      case "clear":
         newElements.map(clear);
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: false },
         });
      case "remove":
         newElements.splice(index, 1);
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: false },
         });
      case "update_children":
         newElements[index].children = children;
         return Object.assign({}, state, { children: newElements });
      case "move":
         [newElements[index], newElements[new_index]] = [
            newElements[new_index],
            newElements[index],
         ];
         return Object.assign({}, state, {
            children: newElements,
            banner: { active: false },
         });
      default:
         return state;
   }
};

const Landing = ({
   item,
   onSubmit,
   isSaving,
   accountCode,
   layout,
   eventCode,
   channelId,
}) => {
   const initItem =
      item ||
      getDefaultLanding({
         ...(accountCode && { account: { code: accountCode } }),
         ...(layout && { layout }),
         ...(eventCode && { eventCode }),
         ...(channelId && { channelId }),
      });
   const children = getChildrenFromComponents(initItem);
   const [landing, dispatch] = useReducer(
      reducer,
      Object.assign({}, initialState, { children })
   );
   const [preview, setPreview] = useState(null);
   const [height, setHeight] = useState(0);
   const accountListWrapper = useSelector((state) => state.accountListWrapper);

   const headerTitle = useRef();
   useEffect(() => {
      setHeight(headerTitle?.current?.offsetHeight);
   }, [headerTitle]);

   const setPreviewMode = (mode) => {
      setPreview(Object.assign({}, preview, { mode }));
   };

   const methods = useForm({
      mode: "onTouched",
      defaultValues: initItem,
   });
   const components = useFieldArray({
      control: methods.control,
      name: "components",
   });

   const onSubmitEvent = methods.handleSubmit((data) => {
      const selectedAccount = accountListWrapper?.items?.find(
         (i) => i.code === initItem.account.code
      );
      const result = transformLandingFromData(data, selectedAccount);
      result.layout = initItem.layout;
      dispatch({ type: "clear" });
      onSubmit(result);
   });

   const onCreate = (type) => {
      dispatch({ type: "add", data: { active: true, type } });
   };

   const onSelect = (index) => {
      dispatch({ type: "select", data: { index } });
   };

   const onUpdate = useCallback((index, children) => {
      dispatch({ type: "update_children", data: { index, children } });
   }, []);

   const onRemove = (index) => {
      dispatch({ type: "remove", data: { index } });
   };

   const onSelectBanner = () => {
      dispatch({ type: "banner", data: { banner: { active: true } } });
   };

   const onMove = (index, new_index) => {
      dispatch({ type: "move", data: { index, new_index } });
   };

   const drawElement = (type) => {
      const { fields, append, remove } = components;
      const createComponent = () => {
         append(getComponent(type));
         onCreate(type);
      };
      const removeComponent = (index) => {
         remove(index);
         onRemove(index);
      };
      return (
         <>
            {fields.map((component, index) => {
               if (component.type !== type) {
                  //se hace así para conservar el index con respecto al resto de componentes
                  return null;
               }
               return (
                  <div className="w-full" key={component.id}>
                     <Element
                        component={component}
                        index={index}
                        name="components"
                        active={
                           landing.children &&
                           landing.children[index] &&
                           landing.children[index].active
                        }
                        onSelect={onSelect}
                        onRemove={removeComponent}
                        className=""
                     />
                  </div>
               );
            })}
            {!fields.find((item) => item.type === type) && (
               <NewElement
                  onCreate={createComponent}
                  types={[type]}
                  className="h-20 flex justify-center items-center"
               />
            )}
         </>
      );
   };

   const showPreview = () => {
      setPreview({
         show: true,
         mode: enums.previewMode.DESKTOP,
         url: `${process.env.REACT_APP_preview_site}/preview/${item.id}`,
      });
   };

   return (
      <>
         <FormProvider {...methods}>
            <div
               className="fixed w-full flex justify-between items-center bg-white border-b top-0 left-0 z-20"
               ref={headerTitle}
            >
               <HeaderTitle item={initItem} />
               <div className="p-4">
                  <Button.Group spaced>
                     {initItem.status === enums.status.PUBLISHED &&
                        initItem.account?.code !== "" && (
                           <a
                              href={`${process.env.REACT_APP_preview_site}/${
                                 initItem.account.code
                              }${initItem.uri ? `/${initItem.uri}` : ""}/${
                                 initItem.id
                              }`}
                              className="mr-8 hover:underline text-blue-600 inline-flex items-center"
                              target="_blank"
                              rel="noreferrer"
                           >
                              <span className="h-4 w-4 mr-2 inline-block">
                                 <ExternalLink />
                              </span>
                              Ver página
                           </a>
                        )}
                     <Button disabled={!initItem.id} onClick={showPreview}>
                        Vista previa
                     </Button>
                     <Button
                        type="submit"
                        bsStyle="success"
                        onClick={onSubmitEvent}
                        isLoading={isSaving}
                        disabled={isSaving}
                        hasAnimation
                     >
                        Guardar
                     </Button>
                  </Button.Group>
               </div>
            </div>
            <form>
               <div
                  className="flex flex-col md:flex-row"
                  style={{ marginTop: height }}
               >
                  <div className="flex flex-col justify-center items-center w-full bg-gray-50">
                     {drawElement(enums.elementType.HEADER)}
                     <div className="w-full max-w-full xl:max-w-screen-lg 2xl:max-w-screen-xl bg-white">
                        <Banner
                           onSelect={onSelectBanner}
                           active={landing.banner.active}
                           dispatch={dispatch}
                           widgetStyle={methods.watch("banner.widgetStyle")}
                           alignment={methods.watch("banner.alignment")}
                           bannerImages={methods.watch("banner.images")}
                           layout={initItem.layout}
                           watch={methods.watch}
                        />
                        <Layout type={initItem.layout}>
                           <Layout.Main>
                              <ElementList
                                 components={components}
                                 group={landing}
                                 onCreate={onCreate}
                                 onSelect={onSelect}
                                 onUpdate={onUpdate}
                                 onRemove={onRemove}
                                 onMove={onMove}
                                 name="components"
                                 excludedTypes={[enums.elementType.HEADER]}
                              />
                           </Layout.Main>
                           {initItem.layout === enums.layout.TWO_COLUMNS && (
                              <Layout.Side>
                                 <Widget style={enums.widgetStyle.SIDEBAR} />
                              </Layout.Side>
                           )}
                        </Layout>
                     </div>
                  </div>
                  <div>
                     <div className="relative lg:w-82 md:w-64 w-full">
                        <div
                           className="md:fixed lg:w-82 md:w-64 w-full h-full flex flex-col pb-6 border-l border-gray-200 overflow-y-scroll"
                           {...(height && {
                              style: {
                                 marginTop: height * -1,
                                 paddingTop: height,
                              },
                           })}
                        >
                           <div className="mb-10 h-full overflow-x-scroll">
                              <div
                                 className={`${
                                    !landing.children.some((e) => e.active) &&
                                    !landing.banner.active
                                       ? "block"
                                       : "hidden"
                                 }`}
                              >
                                 <ConfigurationForm />
                              </div>
                              <div
                                 className={`${
                                    landing.banner.active ? "block" : "hidden"
                                 }`}
                              >
                                 <BannerForm
                                    field={{
                                       fullWidth: true,
                                       fullScreen: false,
                                       keepAspectRatio: true,
                                       theme: enums.bannerTheme.DARK,
                                    }}
                                    layout={initItem.layout}
                                 />
                              </div>
                              {components.fields.map((field, index) => {
                                 return (
                                    <div
                                       key={field.id}
                                       className={
                                          landing.children[index].active
                                             ? "block"
                                             : "hidden"
                                       }
                                    >
                                       <FormList
                                          field={field}
                                          item={landing.children[index]}
                                          name={`components.${index}`}
                                       />
                                    </div>
                                 );
                              })}
                           </div>
                           {(landing.children.some((e) => e.active) ||
                              landing.banner.active) && (
                              <div className="absolute bottom-0 right-0 left-0 border-t border-gray-200 py-3 px-4">
                                 <Button.Group spaced>
                                    <Button onClick={onSubmitEvent}>
                                       Guardar bloque
                                    </Button>
                                 </Button.Group>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </FormProvider>
         {preview && preview.show && (
            <Preview
               mode={preview.mode}
               url={preview.url}
               onClose={() => setPreview(null)}
               onChange={setPreviewMode}
            />
         )}
      </>
   );
};

export default Landing;
