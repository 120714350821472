import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "../../fields";
import { ConnectForm } from "../Landing";
import hotelListActions from '../../../redux/actions/hotelList';
import { getHotelListWrapper } from "../../../access/hotelList";
import validationUtils from "../../../utils/validationUtils";
import { useEffect } from "react";

const HotelListForm = ({ name, accountCode }) => {
   const dispatch = useDispatch();
   const hotelListWrapper = useSelector(state => state.hotelListWrapper);

   useEffect(()=>{
      return () => {
         dispatch(hotelListActions.clear());
      }
   }, [dispatch]);

   useEffect(() => {
      if (hotelListWrapper.isPristine) {
         dispatch(hotelListActions.fetching());
         async function getAsync() {
            const data = await getHotelListWrapper(accountCode);
            dispatch(hotelListActions.get(data));
         }
         dispatch(hotelListActions.fetching());
         getAsync();
      }
   }, [dispatch, hotelListWrapper, accountCode]);

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Lista de hoteles</h3>
         </div>
         <ConnectForm>
            {({ register, formState: { errors } }) => (
               <>
                  <input type="hidden" {...register(`${name}.type`)} />
                  <div className="p-4">
                     {!hotelListWrapper.isPristine && !hotelListWrapper.isFetching && (
                        <Controller
                           name={`${name}.hotelIds`}
                           rules={{
                              validate: (value) => {
                                 return value !== null && value !== undefined && value.length > 0;
                              }
                           }}
                           render={({ field }) =>{
                              const componentError = validationUtils.getPropertyValue(errors, name);
                              const hasError = componentError?.hotelIds?.type === 'validate';

                              return (
                                 <MultiSelect
                                    field={field}
                                    hasError={hasError}
                                    label="Hoteles"
                                    options={hotelListWrapper.items.map(destination => ({
                                       label: destination.name,
                                       options: destination.hotels.map(hotel => ({
                                          value: hotel.id,
                                          label: hotel.name
                                       }))
                                    }))}
                                    getValue={(options) => {
                                       const result = [];
                                       if(field && field.value){     
                                          field?.value.forEach(value => {
                                             options.forEach(group => {
                                                const item = group.options.find(option => option.value === value);
                                                if(item){
                                                   result.push(item);
                                                }
                                             });
                                          });
                                       }
                                       return result;
                                    }}
                                 />
                              );
                           }}
                        />
                     )}
                  </div>
               </>
            )}
         </ConnectForm>
      </>
   );
};

export default (HotelListForm);
