import './form.css';
import ColorPicker from '../common/ColorPicker';
import { forwardRef } from 'react';

const Color = forwardRef(({ label, helpText, value, onChange }, ref) => {
   return (
      <div className="mb-4">
         {label && (
            <label className="inline-block mb-2">
               {
                  label
               }
            </label>
         )}
         <ColorPicker color={value} onChange={onChange} />
         {helpText && (
            <small className="text-gray-400 block mb-2">
               {
                  helpText
               }
            </small>
         )}
      </div>
   );
});

export default Color;
