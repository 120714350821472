import { useCallback, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import Button from "./Button";

const MultiButton = ({ children, topOptions, bottomOptions, main, onClick }) => {
   const [show, setShow] = useState(false);
   const outer = useRef();
   const close = useCallback(() => setShow(false), []);
   useClickOutside(outer, close);

   const toggleMenu = () => {
      setShow(!show);
   };

   return (
      <Button.Group spaced={false} className="relative" ref={outer}>
         {children ? children : (
            <button
               type="button"
               className="inline-block leading-6 py-1.5 px-3 border rounded-md font-medium focus:outline-none disabled:opacity-50 border-gray-300 text-gray-700 bg-white hover:bg-gray-100 itm-btn"
               onClick={() => onClick(main)}
            >
               {main.name}
            </button>
         )}
         {(topOptions.length > 0 || bottomOptions.length > 0) && (
            <>
               {show && (
                  <div className="absolute bg-white shadow border border-gray-200 z-10 top-0 -left-36 w-36">
                     {topOptions.map((action, i) => (
                        <button
                           type="button"
                           key={`top-${i}`}
                           className="focus:outline-none py-2 px-4 hover:bg-gray-50 block w-full text-left"
                           onClick={() => onClick(action)}
                        >
                           {action.name}
                        </button>
                     ))}
                     {bottomOptions.length > 0 && (
                        <>
                           {topOptions.length > 0 && (
                              <hr />
                           )}
                           {bottomOptions.map((action, i) => (
                              <button
                                 type="button"
                                 key={`bottom-${i}`}
                                 className="focus:outline-none py-2 px-4 hover:bg-gray-50 block w-full text-left"
                                 onClick={() => onClick(action)}
                              >
                                 {action.name}
                              </button>
                           ))}
                        </>
                     )}
                  </div>

               )}
               <Button onClick={toggleMenu}>
                  <div className="invisible inline-block max-w-0">.</div>
                  <div className="inline-block border-4 border-transparent" style={{ borderTopColor: '#000' }} />
               </Button>
            </>
         )}

      </Button.Group>
   )
}

export default MultiButton
