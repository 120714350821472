import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import enums from "../../../constants/enums";
import { StyledInput } from "../../fields";
import { Button } from "../../common";
import { formatImage } from "../../../utils/imageUtils";

const CallToAction = ({ active, name, styleOptions }) => {
   const { control } = useFormContext();
   const { alignment, textColor, bgColor, bgImage, bgType, ...buttonStyleOptions } = styleOptions
   const { buttonStyle, buttonBgColor, buttonTextColor, buttonBorderColor } = buttonStyleOptions;
   const className = classNames("border p-2", {
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });
   const actionClassName = classNames("w-full px-8 py-24 bg-cover bg-center", {
      "text-center": alignment === enums.alignment.CENTER,
      "text-right": alignment === enums.alignment.RIGHT
   });

   const getStyle = () => {
      let style = {};
      if (bgType === enums.backgroundType.IMAGE && bgImage) {
         style.backgroundImage = `url("${formatImage(bgImage, {w: 680, fit: 'crop'})}")`;
      } else {
         style.backgroundColor = bgColor;
      }
      return style;
   };

   const getButtonStyle = () => {
      let result = null;
      if (buttonStyle === enums.styleType.CUSTOM) {
         result = {
            buttonStyle: {
               borderColor: buttonBorderColor,
               backgroundColor: buttonBgColor,
               color: buttonTextColor
            }
         };
      }
      return result;
   };

   return (
      <div className={className}>
         <div className={actionClassName} style={getStyle()}>
            <div className="w-full cursor-pointer mb-8">
               <Controller
                  control={control}
                  name={`${name}.content`}
                  render={({ field }) => (
                     <StyledInput
                        alignment={alignment}
                        className="text-2xl"
                        emptyClassName="text-gray-400"
                        textStyle={{ color: textColor }}
                        {...field}
                     />
                  )}
               />
            </div>
            <div>
               <Button {...getButtonStyle()}>
                  <Controller
                     control={control}
                     name={`${name}.buttonText`}
                     render={({ field }) => (
                        <StyledInput
                           alignment={alignment}
                           className="text-2xl"
                           emptyClassName="text-gray-400"
                           {...buttonStyle === enums.styleType.CUSTOM && { textStyle: { color: buttonTextColor } }}
                           {...field}
                        />
                     )}
                  />
               </Button>
            </div>
         </div>

      </div>
   );
};

export default CallToAction;