import { ConnectForm } from "../Landing";
import { Controller } from "react-hook-form";
import { Photo } from "../../fields";
import enums from "../../../constants/enums";

const GalleryForm = ({ name }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Imagen</h3>
         </div>
         <div className="p-4">
            <ConnectForm>
               {({register, watch}) => (
                  <>
                     <input type="hidden" {...register(`${name}.type`)} />
                     <div className="uppercase mb-4 font-semibold">Imágenes</div>
                     <Controller
                        name={`${name}.images`}
                        render={({ field: { ref, ...rest } }) => (
                           <Photo {...rest} aspectRatio={enums.aspectRatio.WIDE} folder={watch('account.code')} />
                        )}
                     />
                  </>
               )}
            </ConnectForm>
         </div>
      </>
   );
};

export default GalleryForm;
