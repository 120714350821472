import { useState, forwardRef } from "react";
import enums from "../../constants/enums";
import { formatImage } from "../../utils/imageUtils";
import { PhotoSelector } from "../common";

const Photo = forwardRef(({ value, onChange, single, aspectRatio, customFormat, folder }, ref) => {
   const [open, setOpen] = useState(false);

   const getSelected = () => {
      let selected = {};
      if (single && value) {
         selected[value.id] = 1;
      } else if (value && value.length > 0) {
         value.forEach((photo, index) => {
            selected[photo.id] = index + 1
         });

      }
      return selected;
   };

   const confirmPhotos = (photos) => {
      const newPhotos = photos
      .slice()
      .map(photo => ({
         name: photo.name,
         path: photo.path
      }));
      if (single && photos.length > 0) {
         onChange(newPhotos[0]);
      } else {
         onChange(newPhotos);
      }
      setOpen(false);
   };

   const deletePhotos = () => {
      onChange(null);
   };

   const hasValue = () => {
      return single ? value != null : (value && value.length > 0);
   };

   const getImage = () => {
      const image = single ? value : value[0];
      let format = {};
      switch (aspectRatio) {
         case enums.aspectRatio.WIDE:
            format = { w: 240, h: 135, fit: "crop", quality: 'low' };
            break;
         case enums.aspectRatio.PORTRAIT:
            format = { w: 180, h: 240, fit: "crop", quality: 'low' };
            break;
         case enums.aspectRatio.SQUARE:
            format = { w: 200, h: 200, fit: "pad", quality: 'low' };
            break;
         case enums.aspectRatio.NONE:
            format = { w: 200, h: 200, fit: "pad", quality: 'low' };
            if (customFormat) {
               format = customFormat;
            }
            break;
         default:
            format = { w: 200, h: 200, fit: "crop", quality: 'low' };
            break;
      }

      return formatImage(image, format);
   };

   return (
      <>
         <div ref={ref} className="border border-gray-200 p-2 mb-4">
            <div
               className={`itm-photoPicker-field-image hover:underline ${hasValue() > 0 ? "bg-square" : "h-36"}`}
               onClick={() => setOpen(true)}
            >
               {hasValue() ? (
                  <img src={getImage()} alt={value.name} className="object-contain w-full h-full" />
               ) : (
                  <span>{`Seleccionar ${single ? 'imagen' : 'imágenes'}`}</span>
               )}
            </div>
            {hasValue() && (
               <div className="flex justify-between mt-2">
                  <button
                     type="button"
                     className="hover:underline text-blue-500"
                     onClick={() => setOpen(true)}
                  >
                     Cambiar imagen
                  </button>
                  <button
                     type="button"
                     className="hover:underline text-blue-500"
                     onClick={deletePhotos}
                  >
                     Eliminar
                  </button>
               </div>
            )}
         </div>
         {open && (
            <PhotoSelector
               single={single}
               selected={getSelected()}
               onClose={() => setOpen(false)}
               onConfirm={confirmPhotos}
               aspectRatio={aspectRatio}
               customFormat={customFormat}
               folder={folder}
            />
         )}
      </>
   );
});

export default Photo;
