import { useState } from 'react';
import { TextArea, Select, Radio, Input } from '../../fields';
import enums from '../../../constants/enums';
import { useFormContext } from 'react-hook-form';


const ConfigurationForm = () => {
   const { register, formState: { errors }, getValues } = useFormContext();
   const advanced = getValues(['uri', 'tagManager']).filter((value) => value);
   const [advancedIsOpen, setAdvancedIsOpen] = useState(advanced.length > 0);
   const toggleAdvanced = () => {
      setAdvancedIsOpen(!advancedIsOpen);
   };

   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Configuración de la página</h3>
         </div>
         <div className="p-4">
            <>
               <input type="hidden" {...register(`account.code`)} />
               <input type="hidden" {...register(`layout`)} />

               <TextArea label="Nombre" name="title" register={register} required hasError={errors.title} />
               {errors.title && (
                  <div className="text-red-400 mb-4 text-sm">Se necesita un nombre</div>
               )}

               <Select label="Idioma" register={register} name={"language"}>
                  <Select.Option value="es-mx">Español</Select.Option>
                  <Select.Option value="en-us">Inglés</Select.Option>
               </Select>

               <Radio label="Estatus" name="status" register={register}>
                  <Radio.Option value={enums.status.PUBLISHED}>Publicado</Radio.Option>
                  <Radio.Option value={enums.status.DRAFT}>Borrador</Radio.Option>
                  <Radio.Option value={enums.status.INACTIVE}>Inactivo</Radio.Option>
               </Radio>

               <div className="mb-4 cursor-pointer" onClick={toggleAdvanced}>Opciónes avanzadas {advancedIsOpen
                  ? <svg className="h-4 w-4 inline-block" viewBox="0 0 24 24"><path d="M18 15l-6-6-6 6" /></svg>
                  : <svg className="h-4 w-4 inline-block" viewBox="0 0 24 24"><path d="M6 9l6 6 6-6" /></svg>
               }</div>
               {advancedIsOpen && (
                  <>
                     <Input label="Url" name="uri" register={register} validationOptions={{ pattern: /^[0-9A-Za-z-_]+$/i }} errors={errors} />
                     {errors.uri && (
                        <div className="text-red-400 mb-4 text-sm">Debe ser una uri válida</div>
                     )}
                     <Input label="Google Tag Manager Id" name="tagManager" register={register} />
                     <Input label="Meta Title" name="seo.title" register={register} />
                     <TextArea label="Meta Description" name="seo.description" register={register} />
                  </>
               )}
            </>
         </div>
      </>
   );
};

export default ConfigurationForm;
