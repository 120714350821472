import enums from "../../../constants/enums";

const Widget = ({ style }) => {
   let result = null;
   const verticalBooker = (
      <>
         <div className="text-gray-400 text-lg">
            <div className="mb-4 border border-gray-300 rounded-sm leading-9 px-4">
               1 habitación, 2 personas
            </div>
            <div className="mb-4 border border-gray-300 rounded-sm leading-9 px-4 flex">
               <div className="flex-grow">Llegada</div>
               <div className="flex-none w-16 ">-</div>
               <div className="flex-grow">Salida</div>
            </div>
            <div className="mb-4 leading-9 text-center bg-gray-300 rounded-sm">
               Ver disponibilidad
            </div>
         </div>
         <div className="text-gray-400">
            ¿Tienes un código de promoción?
         </div>
      </>
   );
   if (style === enums.widgetStyle.HORIZONTAL) {
      result = (
         <div className="absolute bottom-0 w-full">
            <div className="p-6">
               <div className="flex bg-white text-gray-400 text-lg">
                  <div className="flex-auto p-4 border-r">
                     1 habitación, 2 personas
                  </div>
                  <div className="flex-auto p-4 flex">
                     <div className="flex-grow">Llegada</div>
                     <div className="flex-none w-16 ">-</div>
                     <div className="flex-grow">Salida</div>
                  </div>
                  <div className="flex-auto p-4 text-center bg-gray-300">
                     Ver disponibilidad
                  </div>
               </div>
               <div className="text-right text-gray-400 pt-5">
                  ¿Tienes un código de promoción?
               </div>
            </div>
         </div>
      );
   } else if (style === enums.widgetStyle.VERTICAL) {
      result = (
         <div className="absolute w-full px-6 flex justify-end">
            <div className="w-5/12">
               <div className="bg-white p-5 border rounded-md border-black border-opacity-10 mt-16">
                  {verticalBooker}
               </div>
            </div>
         </div>
      );
   } else if (style === enums.widgetStyle.SIDEBAR) {
      result = (
         <div className="my-6 mx-4 border rounded-md py-4 px-6">
            <h2 className="mb-4 text-2xl text-gray-400">Reservar ahora</h2>
            {verticalBooker}
         </div>
      );
   }
   return result;
};

export default Widget;
