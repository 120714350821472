import classNames from "classnames";
import Select from 'react-select';

const MultiSelect = ({ name, label, hasError, field, options, getValue, isMulti, placeholder, disabled, filterOption }) => {
   const { onChange, value, ...rest } = field;
   const labelClassName = classNames("mb-2 inline-block", {
      "text-red-400": hasError
   });

   const onChangeEvent = (items) => {
      if(isMulti){
         onChange(items.map(item => item.value));
      }else{
         onChange(items.value);
      }
   };

   const extras = {
      ...placeholder && {placeholder},
      ...disabled && {isDisabled: disabled},
      ...filterOption && {filterOption}
   };

   return (
      <div className="mb-4">
         {label && (
            <label htmlFor={name} className={labelClassName}>
               {
                  label
               }
            </label>
         )}
         <Select
            {...rest}
            {...hasError && {
               styles:{
                  control: (provided) => ({
                     ...provided,
                     borderColor: 'rgb(248 113 113)'
                  })
            }}}
            onChange={onChangeEvent}
            value={getValue(options)}
            options={options}
            isMulti={isMulti}
            {...extras}
         />
      </div>
   );
};

MultiSelect.defaultProps = {
   isMulti: true
};

const MultiSelectLoading = ({ label }) => (
   <div className="mb-4">
      {label && (
         <label className="mb-2 inline-block">
            {
               label
            }
         </label>
      )}
      Cargando...
   </div>
);

export { MultiSelectLoading };

export default MultiSelect;
