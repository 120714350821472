import { useEffect, useRef, useState } from "react";
import hotelIcons from '../../hotel-icons.svg';
import './IconSelector.css';

const IconSelector = ({ value, onChange, iconStyle }) => {
   const trigger = useRef(null);
   const overlay = useRef(null);
   const [isOpen, setIsOpen] = useState(false);
   const [selectedIcon, setSelectedIcon] = useState(value);

   useEffect(() => {
      window.addEventListener("click", close);
      return () => {
         window.removeEventListener("click", close);
      }
   }, []);

   const toggle = (event) => {
      event.stopPropagation();
      setIsOpen(!isOpen);
   };

   const close = (event) => {
      const isDescendant = overlay && overlay.current && overlay.current.contains(event.target);
      if (!isDescendant) {
         setIsOpen(false);
      }
   };

   const onSelectIcon = (icon) => {
      setSelectedIcon(icon);
      setIsOpen(false);
      onChange(icon);
   };

   return (
      <div className="relative">
         <div
            ref={trigger}
            className="flex justify-center items-center border border-gray-200 mb-8 cursor-pointer itm-icon-selector-button"
            onClick={toggle}
         >
            {selectedIcon && (
               <div style={iconStyle}>
                  <svg className="h-full w-full" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                     <use xlinkHref={`${hotelIcons}#${selectedIcon}`}/>
                  </svg>
               </div>
            )}
         </div>
         {isOpen && (
            <div
               ref={overlay}
               className="itm-icon-selector-container border absolute shadow z-10 bg-white rounded"
            >
               <div className="h-96 overflow-y-auto p-2">
                  <div className="grid grid-cols-5 gap-2 text-center">
                     {[...Array(490).keys()].map(i => (
                        <button type="button" className="focus:outline-none hover:bg-gray-100" key={`icon-${i}`} onClick={() => onSelectIcon(i + 1)}>
                           <div className="flex justify-center py-6">
                                 <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                    <use xlinkHref={`${hotelIcons}#${i+1}`}/>
                                 </svg>
                           </div>
                        </button>
                     ))}
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default IconSelector;
