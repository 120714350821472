import { ConnectForm } from "../Landing";
import { Controller } from "react-hook-form";
import { Photo } from "../../fields";
import { IconButton } from "../../common";
import enums from "../../../constants/enums";

const ImageForm = ({ name, field }) => {
   return (
      <>
         <div className="py-3 px-4 border-b border-gray-200 text-gray-500 font-semibold">
            <h3 className="text-sm uppercase text-center">Imagen</h3>
         </div>
         <div className="p-4">
            <ConnectForm>
               {({register, watch}) => (
                  <>
                     <input type="hidden" {...register(`${name}.type`)} />
                     <div className="uppercase mb-4 font-semibold">Imagen</div>
                     <Controller
                        name={`${name}.image`}
                        defaultValue={field.image}
                        render={({ field }) => (
                           <Photo single {...field} aspectRatio={enums.aspectRatio.WIDE} folder={watch('account.code')} />
                        )}
                     />
                     <Controller
                        name={`${name}.styleOptions.silhouette`}
                        defaultValue={field.styleOptions ? field.styleOptions.silhouette : null}
                        render={({ field: { ref, ...rest } }) => (
                           <>
                              <div className="uppercase mb-4 font-semibold">Estilo</div>
                              <IconButton.Group {...rest}>
                                 <IconButton label="Rectangular" value={enums.silhouette.RECTANGLE} className="h-8 w-12">
                                    <div className="bg-gray-500 h-full w-full"></div>
                                 </IconButton>
                                 <IconButton label="Circular" value={enums.silhouette.CIRCLE} className="h-8 w-10">
                                    <div className="bg-gray-500 h-10 w-full -mt-1 rounded-full"></div>
                                 </IconButton>
                              </IconButton.Group>
                           </>
                        )}
                     />
                  </>
               )}
            </ConnectForm>
         </div>
      </>
   );
};

export default ImageForm;
