import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import { DateTime } from "luxon";
import enums from '../constants/enums';

function transformLandingToTableItem(landing) {
    const getActions = (status) => {
        const actions = {
            main: { name: 'Editar', code: 'EDIT', id: landing.id },
            top: [],
            bottom: [
                { name: 'Eliminar', code: 'DELETE', id: landing.id }
            ]
        };
        if (status === enums.status.PUBLISHED) {
            actions.top.push({
                name: 'Ver página', code: 'VIEW', id: landing.id, accountCode: landing.account && (landing.account.code || ''), uri: landing.uri || null
            });
        }
        if (status === enums.status.DRAFT || status === enums.status.INACTIVE) {
            actions.top.push({
                name: 'Vista previa', code: 'PREVIEW', id: landing.id, accountCode: landing.account && (landing.account.code || '')
            });
            // actions.top.push({
            //     name: 'Publicar', code: 'PUBLISH', id: landing.id
            // });
        }
        actions.top.push({
            name: 'Duplicar', code: 'DUPLICATE', id: landing.id
        });
        return actions;
    };

    return {
        id: landing.id,
        title: landing.title,
        status: landing.status,
        language: landing.language,
        author: landing.author && landing.author.name,
        account: {
            hotel: '',
            name: landing.account && (landing.account.name || landing.account.code)
        },
        lastModified: landing.lastModified || landing.createTime,
        actions: getActions(landing.status)
    };
}

function getChildrenFromComponents(item) {
    const transform = (item) => {
        const newItem = {
            type: item.type,
            active: false,
            children: []
        };
        if (item.children && item.children.length > 0) {
            newItem.children = item.children.map(c => transform(c));
        }
        return newItem;
    };
    return item && item.components ? item.components.map(c => transform(c)) : [];
}

function transformLandingFromData(data, account) {
    const { components, ...rest } = data;
    const result = { ...rest };
    const transform = (component) => {
        const { type, content, children, ...rest } = component;
        const newComponent = { type, ...rest };
        if(type === enums.elementType.HEADER
            && component.contactType === enums.styleType.DEFAULT
        ){
            newComponent.email = account ? account.email : null;
            newComponent.phone = account ? account.phone : null
        }
        if (type === enums.elementType.TEXT && content) {
            newComponent.content = stateToHTML(content.getCurrentContent());
        }else {
            newComponent.content = content;
        }
        if (children) {
            newComponent.children = component.children.map(transform);
        }
        return newComponent;
    };
    result.components = data.components.map(transform);
    return result;
}

function transfromLandingToData(landing) {
    const transform = (item) => {
        if (item.type === enums.elementType.TEXT) {
            const blocksFromHTML = convertFromHTML(item.content);
            const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            item.content = EditorState.createWithContent(content);
        }
        if (item.children && item.children.length > 0) {
            item.children = item.children.map(c => transform(c))
        }
        return item;
    }
    landing.components.map(c => transform(c));
    return landing;
}

function getDefaultLanding(props) {
    const date = DateTime.now().setLocale('es-mx');
    const title = date.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    return {
        title: `Sin título ${title}`,
        status: enums.status.DRAFT,
        language: 'es-mx',
        banner: {
            fullScreen: false,
            fullWidth: true,
            widgetStyle: props.layout === enums.layout.TWO_COLUMNS ? enums.widgetStyle.NONE : enums.widgetStyle.HORIZONTAL,
            alignment: enums.alignment.LEFT
        },
        components: [],
        ...props
    };
}

export {
    transformLandingToTableItem,
    getChildrenFromComponents,
    transformLandingFromData,
    transfromLandingToData,
    getDefaultLanding
};
