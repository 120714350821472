import { HashRouter as Router, Switch } from 'react-router-dom';
import { List, Create, Edit } from './components/landings';
import { createContext, useMemo, useState } from 'react';
import PrivateRoute from './components/common/PrivateRoute';
import useAuth from './hooks/useAuth';

export const AuthContext = createContext();

function App() {
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({
    user,
    setUser
  }), [user]);

  useAuth({
    setUser
  });

  return (
    <AuthContext.Provider value={value} >
      <Router>
        <Switch>
          <PrivateRoute path={`/:accountCode/create`}>
            <Create />
          </PrivateRoute>
          <PrivateRoute path={`/edit/:landingId`}>
            <Edit />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <List />
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
