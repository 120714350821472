import { useState } from "react";
import Landing from "./Landing";
import { createLanding } from "../../access/landing";
import { Redirect, useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const Create = () => {
   const { accountCode } = useParams();
   const query = useQuery();
   const [isSaving, setIsSaving] = useState(false);
   const [landingId, setLandingId] = useState(null);

   const save = (data) => {
      setIsSaving(true);
      createLanding(data).then((result) => {
         setLandingId(result.id);
         setIsSaving(false);
      });
   };

   if (landingId) {
      return <Redirect to={`/edit/${landingId}`} />;
   }
   return (
      <Landing
         onSubmit={save}
         isSaving={isSaving}
         accountCode={accountCode}
         layout={query.get("layout")}
         eventCode={query.get("eventCode")}
         channelId={query.get("channelId")}
      />
   );
};

export default Create;
