import { createTypes, asyncAction } from "redux-action-types";

export const types = createTypes(
   "channel",
   asyncAction("GET_LIST"),
   "CLEAR_LIST"
);

const get = (data) => ({
   type: types.GET_LIST_SUCCESS,
   data,
});

const fetching = () => ({
   type: types.GET_LIST_REQUEST,
});

const clear = () => ({
   type: types.CLEAR_LIST,
});

const result = {
   get,
   fetching,
   clear,
};

export default result;
