import { types } from '../actions/landing';

const initialState = {
    item: null,
    isFetching: false,
    isPristine: true
};

export default function landingReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SUCCESS:
            return {
                ...state,
                item: action.data,
                isFetching: false,
                isPristine: false
            };
        case types.GET_REQUEST:
            return {
                ...state,
                isFetching: true,
                isPristine: false
            };
        case types.CLEAR:
            return initialState;
        default:
            return state;
    }
}