import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Controller, useFormContext } from "react-hook-form";
import Text from "./Text";
import Row from "./Row";
import Image from "./Image";
import Gallery from "./Gallery";
import CallToAction from "./CallToAction";
import ButtonComponent from "./ButtonComponent";
import Video from "./Video";
import { Button } from "../../common";
import './Element.css';
import IconList from "./IconList";
import enums from "../../../constants/enums";
import Header from "./Header";
import HotelList from "./HotelList";

const Element = ({ component, name, index, active, onSelect, onUpdate, onRemove, onFreeMoveClick, onRowMoveClick, className, isMoving, length, componentSize }) => {
   const { control, watch } = useFormContext();
   let result;
   const common = {
      active,
      componentSize
   };

   switch (component.type) {
      case enums.elementType.TEXT:
         const textWatchProps = {
            type: watch(`${name}.${index}.styleOptions.textType`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            backgroundColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            fontSize: watch(`${name}.${index}.styleOptions.fontSize`),
            fontSizeCode: watch(`${name}.${index}.styleOptions.fontSizeCode`),
         };
         result = (
            <Controller
               control={control}
               name={`${name}.${index}.content`}
               defaultValue={component.content}
               render={({ field: { ref, ...rest } }) => (
                  <Text {...rest} {...common} styleOptions={textWatchProps} />
               )}
            />
         );
         break;
      case enums.elementType.ROW:
         const size = watch(`${name}.${index}.size`);
         result = (
            <Row {...common}
               index={index}
               name={name}
               onUpdate={onUpdate}
               children={component.children}
               size={size}
               control={control}
            />
         );
         break;
      case enums.elementType.IMAGE:
         const image = watch(`${name}.${index}.image`);
         const silhouette = watch(`${name}.${index}.styleOptions.silhouette`);
         result = (
            <Image image={image} silhouette={silhouette} {...common} />
         );
         break;
      case enums.elementType.GALLERY:
         const images = watch(`${name}.${index}.images`);
         result = (
            <Gallery images={images} {...common} />
         );
         break;
      case enums.elementType.CALL_TO_ACTION:
         const callToActionWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            bgColor: watch(`${name}.${index}.styleOptions.backgroundColor`),
            bgImage: watch(`${name}.${index}.image`),
            bgType: watch(`${name}.${index}.styleOptions.backgroundType`),
            buttonStyle: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonBgColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`)
         };
         result = (
            <CallToAction
               name={`${name}.${index}`}
               styleOptions={callToActionWatchProps}
               {...common}
            />
         );
         break;
      case enums.elementType.BUTTON:
         const buttonWatchProps = {
            alignment: watch(`${name}.${index}.styleOptions.alignment`),
            buttonStyle: watch(`${name}.${index}.styleOptions.buttonType`),
            buttonBgColor: watch(`${name}.${index}.styleOptions.buttonBackgroundColor`),
            buttonTextColor: watch(`${name}.${index}.styleOptions.buttonTextColor`),
            buttonBorderColor: watch(`${name}.${index}.styleOptions.buttonBorderColor`)
         };
         result = (
            <ButtonComponent
               name={`${name}.${index}.buttonText`}
               styleOptions={buttonWatchProps}
               {...common}
            />
         );
         break;
      case enums.elementType.VIDEO:
         const videoUrl = watch(`${name}.${index}.url`);
         result = (
            <Video url={videoUrl} title={`${name}.${index}`} {...common} />
         );
         break;
      case enums.elementType.ICON_LIST:
         const iconListWatchProps = {
            size: watch(`${name}.${index}.size`),
            styleProps: {
               style: watch(`${name}.${index}.styleOptions.textType`),
               textColor: watch(`${name}.${index}.styleOptions.textColor`),
               textSize: watch(`${name}.${index}.styleOptions.fontSizeCode`),
               customTextSize: watch(`${name}.${index}.styleOptions.fontSize`),
               iconColor: watch(`${name}.${index}.styleOptions.iconColor`),
               iconSize: watch(`${name}.${index}.styleOptions.iconSizeCode`),
               customIconSize: watch(`${name}.${index}.styleOptions.iconSize`)
            }
         };
         result = (
            <IconList
               name={`${name}.${index}.icons`}
               control={control}
               size={iconListWatchProps.size}
               styleOptions={iconListWatchProps.styleProps}
               {...common}
            />
         );
         break;
      case enums.elementType.HOTEL_LIST:
         result = (
            <HotelList
               {...common}
            />
         );
         break;
      case enums.elementType.HEADER:
         const logo = watch(`${name}.${index}.image`);
         const phone = watch(`${name}.${index}.phone`);
         const email = watch(`${name}.${index}.email`);
         const contactType = watch(`${name}.${index}.contactType`);
         const headerWatchProps = {
            textColor: watch(`${name}.${index}.styleOptions.textColor`),
            bgColor: watch(`${name}.${index}.styleOptions.backgroundColor`)
         };
         const accountCode = watch(`account.code`);
         result = (
            <Header
               {...common}
               image={logo}
               phone={phone}
               email={email}
               type={contactType}
               styleOptions={headerWatchProps}
               accountCode={accountCode}
            />
         );
         break;
      default:
         result = (
            <div>{`componente: ${component.type}`}</div>
         );
   }
   const onRemoveEvent = (event, index) => {
      event.stopPropagation();
      if(!isMoving){
         onRemove(index);
      }
   };

   const onFreeMoveClickEvent = (event) => {
      event.stopPropagation();
      onFreeMoveClick();
   };

   const onRowMoveClickEvent = (event, up, index) => {
      event.stopPropagation();
      if(!isMoving){
         onRowMoveClick(index, up);
      }
   };

   return (
      <div className={className}>
         <div onClick={() => onSelect(index)} className="relative itm-element-container" >
            {result}
            {!active && (
               <div className={`absolute ${component.type === enums.elementType.ROW ? "-top-4" : "top-2"} right-2 hidden itm-showOnHover`}>
                  <Button.Group spaced={false}>
                     {onFreeMoveClick && (
                        <Button onClick={onFreeMoveClickEvent}>
                           <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.677 490.677">
                              <path d="M487.557,237.789l-64-64c-3.051-3.051-7.659-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v32h-128v-128h32
			c4.309,0,8.213-2.603,9.856-6.592c1.643-3.989,0.725-8.576-2.304-11.627l-64-64c-4.16-4.16-10.923-4.16-15.083,0l-64,64
			c-3.051,3.072-3.968,7.637-2.325,11.627c1.643,3.989,5.547,6.592,9.856,6.592h32v128h-128v-32c0-4.309-2.603-8.213-6.592-9.856
			c-3.925-1.664-8.555-0.747-11.627,2.304l-64,64c-4.16,4.16-4.16,10.923,0,15.083l64,64c3.072,3.072,7.68,4.011,11.627,2.304
			c3.989-1.621,6.592-5.525,6.592-9.835v-32h128v128h-32c-4.309,0-8.213,2.603-9.856,6.592c-1.643,3.989-0.725,8.576,2.304,11.627
			l64,64c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115l64-64c3.051-3.051,3.968-7.637,2.304-11.627
			c-1.664-3.989-5.547-6.592-9.856-6.592h-32v-128h128v32c0,4.309,2.603,8.213,6.592,9.856c3.947,1.685,8.576,0.747,11.627-2.304
			l64-64C491.717,248.712,491.717,241.971,487.557,237.789z"/>
                           </svg>
                        </Button>
                     )}
                     {onRowMoveClick && (
                        <>
                           <Button onClick={(event) => onRowMoveClickEvent(event, true, index)} className={index === 0 || isMoving ? "opacity-50" : ""}>
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255">
                                 <polygon points="0,191.25 127.5,63.75 255,191.25" />
                              </svg>
                           </Button>
                           <Button onClick={(event) => onRowMoveClickEvent(event, false, index)} className={index === length -1 || isMoving ? "opacity-50" : ""}>
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 255">
                                 <polygon points="0,63.75 127.5,191.25 255,63.75" />
                              </svg>
                           </Button>
                        </>
                     )}
                     <Button onClick={(event) => onRemoveEvent(event, index)} className={isMoving ? "opacity-50" : ""}>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" >
                           <path d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                           <polygon points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
                        </svg>
                     </Button>
                  </Button.Group>
               </div>
            )}
         </div>
      </div>
   );
};

Element.defaultProps = {
   className: "my-6 mx-4"
};

export const DDElement = (props) => {
   const { component, index, onMove, onDrop } = props;
   const ref = useRef(null);
   const [{ isDragging }, drag] = useDrag({
      type: 'Element',
      item: () => {
         return {
            id: component.id,
            index
         }
      },
      collect: (monitor) => ({
         isDragging: !!monitor.isDragging()
      })
   });
   const [{ handlerId }, drop] = useDrop({
      accept: 'Element',
      collect(monitor) {
         return {
            handlerId: monitor.getHandlerId()
         };
      },
      hover(item, monitor) {
         if (!ref.current) {
            return;
         }
         const dragIndex = item.index;
         const hoverIndex = index;
         if (dragIndex === hoverIndex) {
            return;
         }
         const hoverBoundingRect = ref.current?.getBoundingClientRect();
         const hoverMiddleY = (hoverBoundingRect.top - hoverBoundingRect.bottom) / 2;
         const clientOffset = monitor.getClientOffset();
         const hoverClientY = clientOffset.y - hoverBoundingRect.bottom;
         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
         }
         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
         }
         onMove(dragIndex, hoverIndex);
         item.index = hoverIndex;
      },
      drop(item){
         onDrop(item);
      }
   });
   drag(drop(ref));
   return (
      <div ref={ref} className={`itm-dd-element relative my-6 mx-4 bg-white ${isDragging ? 'opacity-50' : ''}`} data-handler-id={handlerId}>
         <Element {...props} isMoving className="" />
         <div className="absolute inset-0 cursor-move"></div>
      </div>
   );
}

export default Element;
