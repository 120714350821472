import { Button } from "./";
import enums from "../../constants/enums";

const AlignmentSelector = ({ label, value, onChange }) => {
   return (
      <div className="mb-4">
         <div className="mb-2">{label}</div>
         <Button.Group spaced={false}>
            <Button
               className={value === enums.alignment.LEFT ? 'bg-gray-200' : ''}
               onClick={() => onChange(enums.alignment.LEFT)}
            >
               <svg className="block h-4 w-4" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 333 333">
                  <path d="M323,31.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,31.5,323,31.5z" />
                  <path d="M230,114.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h220c5.5,0,10-4.5,10-10S235.5,114.5,230,114.5z" />
                  <path d="M323,198.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,198.5,323,198.5z" />
                  <path d="M151,281.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h141c5.5,0,10-4.5,10-10S156.5,281.5,151,281.5z" />
               </svg>
            </Button>
            <Button
               className={value === enums.alignment.CENTER ? 'bg-gray-200' : ''}
               onClick={() => onChange(enums.alignment.CENTER)}
            >
               <svg className="block h-4 w-4" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 333 333" >
                  <path d="M323,31.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,31.5,323,31.5z" />
                  <path d="M276,114.5H56c-5.5,0-10,4.5-10,10s4.5,10,10,10h220c5.5,0,10-4.5,10-10S281.5,114.5,276,114.5z" />
                  <path d="M323,198.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,198.5,323,198.5z" />
                  <path d="M234,281.5H93c-5.5,0-10,4.5-10,10s4.5,10,10,10h141c5.5,0,10-4.5,10-10S239.5,281.5,234,281.5z" />
               </svg>
            </Button>
            <Button
               className={value === enums.alignment.RIGHT ? 'bg-gray-200' : ''}
               onClick={() => onChange(enums.alignment.RIGHT)}
            >
               <svg className="block h-4 w-4" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 333 333">
                  <path d="M323,31.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,31.5,323,31.5z" />
                  <path d="M323,114.5H104c-5.5,0-10,4.5-10,10s4.5,10,10,10h219c5.5,0,10-4.5,10-10S328.5,114.5,323,114.5z" />
                  <path d="M323,198.5H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h313c5.5,0,10-4.5,10-10S328.5,198.5,323,198.5z" />
                  <path d="M323,281.5H182c-5.5,0-10,4.5-10,10s4.5,10,10,10h141c5.5,0,10-4.5,10-10S328.5,281.5,323,281.5z" />
               </svg>
            </Button>
         </Button.Group>
      </div>
   );
};

export default AlignmentSelector