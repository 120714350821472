const Body = ({ children }) => {
   return (
      <main>
         <div className="max-w-screen-xl mx-auto px-4">
            {children}
         </div>
      </main>
   )
}

export default Body;
