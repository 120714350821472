import classNames from "classnames";
import { Button } from "../../common";

const HotelList = ({ active, componentSize }) => {
   const className = classNames("border p-2", {
      "border-indigo-600": active,
      "cursor-pointer border-gray-200 border-dashed hover:bg-gray-100 cursor-pointer": !active
   });
   const showAsCard = componentSize && (componentSize === "1/2" || componentSize === "4/12");
   const [sizeImg, sizeDescription, sizeRate] = showAsCard
      ? ["w-full", "w-full", "w-full"]
      : ["w-4/12", "w-5/12", "w-3/12"];

   const [sizePrice, sizeButton] = showAsCard
      ? ["w-1/2", "w-1/2"]
      : ["w-full", "w-full"];

   const containerClassName = classNames("flex bg-gray-50 mb-4", {
      "flex-col": showAsCard
   });

   const rateContainerClassName = classNames("flex", {
      "flex-col": !showAsCard
   });


   return (
      <div className={className}>
         {[...Array(2).keys()].map((index) => (
            <div className={containerClassName} key={`prev-hotel-${index}`}>
               <div className={`bg-gray-200 ${sizeImg}`} style={{minHeight: '194px'}}/>
               <div className={`p-4 ${sizeDescription}`}>
                  <h4 className="text-2xl">Lorem ipsum dolor</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eleifend, eros at tincidunt rutrum, metus tellus venenatis ipsum, ut imperdiet orci elit quis turpis</p>
               </div>
               <div className={`p-4 text-right ${sizeRate}`}>
                  <div className={rateContainerClassName}>
                     <div className={`mb-4 ${sizePrice}`}>
                        <div>Maecenas eleifend:</div>
                        <div className="text-2xl">$9,999</div>
                     </div>
                     <div className={`${sizeButton}`}>
                        <Button disabled>Lorem ipsum</Button>
                     </div>
                  </div>
               </div>
            </div>
         ))}
      </div>
   );
};

export default HotelList;
