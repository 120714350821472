import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Landing from "./Landing";
import { getLandingWrapper, saveLanding } from '../../access/landing';
import { fetching, get, clear } from '../../redux/actions/landing';
import Loading from './Loading';

const Edit = () => {
   const { landingId } = useParams();
   const [isSaving, setIsSaving] = useState(false);

   const dispatch = useDispatch();
   const landingWrapper = useSelector(state => state.landingWrapper);
   
   useEffect(() => {
      dispatch(fetching());
      async function getAsync() {
         const data = await getLandingWrapper(landingId);
         dispatch(get(data));
      }
      getAsync();
      return () => {
         dispatch(clear())
      }
   }, [dispatch, landingId]);

   const save = (data) => {
      setIsSaving(true);
      saveLanding(data)
      .then(() => {
         setIsSaving(false);
      });
   };

   if(landingWrapper.isFetching || landingWrapper.isPristine){
      return (
         <Loading />
      );
   }

   return (
      <Landing item={landingWrapper.item} onSubmit={save} isSaving={isSaving}/>
   );
};

export default Edit;
