const Title = ({ title, children }) => {
   return (
      <header className="bg-white">
         <div className="max-w-screen-xl mx-auto px-4">
            <div className="md:flex md:items-center md:justify-between py-6">
               <div className="flex-1 min-w-0">
                  <h1 className="text-3xl font-bold text-gray-900">
                     {title}
                  </h1>
               </div>
               <div className="mt-5 flex md:mt-0 md:ml-4">
                  {children}
               </div>
            </div>
         </div>

      </header>
   )
};

export default Title;
