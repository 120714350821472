import classNames from "classnames";
import { createContext, useContext } from "react";
import { createPortal } from "react-dom";
import usePortal from "../../hooks/usePortal";
import { ReactComponent as CloseIcon } from "../icons/close.svg";

const ModalContext = createContext({});

const Modal = ({ className, outerClassName, children, onClose }) => {
   const modalClassName = classNames(`bg-white shadow rounded w-full m-4 relative ${className}`);
   const modalOuterClassName = classNames(`inset-0 fixed bg-black bg-opacity-50 z-50 flex items-center justify-center ${outerClassName}`);
   const target = usePortal("modal");
   const modal = (
      <div className={modalOuterClassName}>
         <div className={modalClassName}>
            <ModalContext.Provider value={{ onClose }}>
               {children}
            </ModalContext.Provider>
         </div>
      </div>
   );
   return createPortal(modal, target);
};

const Body = ({ children }) => {
   return (
      <div className="p-4">
         {children}
      </div>
   )
};

const Header = ({ children }) => {
   const { onClose } = useContext(ModalContext);
   return (
      <div className="p-4 flex justify-between items-center border-b">
         <div>
            {children}
         </div>
         {onClose && (
            <div>
               <button type="button" className="focus:outline-none align-middle text-gray-400" onClick={onClose}>
                  <div className="h-4 w-4"><CloseIcon /></div>
               </button>
            </div>
         )}
      </div>
   );
};

const Footer = ({ children }) => {
   return (
      <div className="p-4 border-t text-right">
         {children}
      </div>
   );
};

Modal.Body = Body;
Modal.Header = Header;
Modal.Footer = Footer;

Modal.defaultProps = {
   classNames: "",
   outerClassName: ""
};

export default Modal;
